export enum ROUTES {
  ABOUT = "/about",
  AUTH = "/auth",
  AUTHOR_WITH_ID = "/author/:id",
  CREATE = "/create",
  CREATE_POST = "/create/post",
  CREATE_RESTAURANT = "/create/restaurant",
  EXPLORE = "/search",
  GROUP_WITH_ID = "/group/:id",
  HOME = "/",
  LIST = "/list",
  POST = "/post",
  POST_WITH_ID = "/post/:id",
  RANKING = "/ranking",
  RECOMMEND = "/recommend",
  RESTAURANT = "/restaurant",
  RESTAURANT_WITH_ID = "/restaurant/:id",
  SCORING = "/scoring",
}
