import {
  Box,
  Divider,
  Flex,
  HStack,
  Heading,
  Stack,
  Text,
} from "@chakra-ui/react";

import { ReactComponent as QuotationMark } from "assets/quotationmark.svg";

const AboutPage = () => {
  return (
    <Box p={4}>
      <Stack align={"center"}>
        <Box w={"95%"} maxW={"lg"}>
          <Heading>About</Heading>
          <Divider />
          <Stack spacing={5} mt={4}>
            <Text align={"justify"}>
              At Jelly Pancakes Inc, we are motivated to help you discover local
              foods in an easy and enjoyable way. Gone are the days of blindly
              searching and scrolling though social media or google maps. Here,
              you will get best in class recommendations by local that suit your
              taste preferences.
            </Text>
            <Flex gap={5} direction={{ md: "row", base: "column" }}>
              <HStack w={{ md: "50%" }}>
                <Box mb={{ base: 10, md: 32 }}>
                  <QuotationMark style={{ height: 40, width: 40 }} />
                </Box>
                <Box mt={3}>
                  <Text as={"b"} fontSize={{ base: "3xl", md: "4xl" }}>
                    Redefining Food Discovery
                  </Text>
                </Box>
              </HStack>
              <Stack w={{ md: "50%" }}>
                <Text align={"justify"}>
                  We will achieve this through a series of features that will be
                  rolled out progressively. Currently you can see different
                  restaurants that our local reviewers have tried. Reviews will
                  be short and succinct with recommendations on what to order
                  and some images available for you view.
                </Text>
              </Stack>
            </Flex>
            <Text align={"justify"}>
              Our F-score (Food-Score) is a quick and simple way to know what
              our local reviewer think about a particular restaurant. The score
              takes into account a few different aspects of what we believe a
              good restaurant should possess.
            </Text>

            <Text align={"justify"}>
              For our local friends, you can join in the fun by sending your
              recommendations for our local reviewers to try out. You can use
              our website to decide where you and your friends should gather
              next time you all meet as well.
            </Text>

            <Text align={"justify"}>
              For our foreign friends, your time in any country is limited. So
              we hope this would help you better plan where you consume your
              meals.
            </Text>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};

export default AboutPage;
