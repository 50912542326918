import { Box, Button, Flex, Heading, Stack, Text } from "@chakra-ui/react";
import { IoMdHome, IoMdPaper } from "react-icons/io";
import { useNavigate } from "react-router-dom";

import { ROUTES } from "constants/routes";

const CreatePage = () => {
  const navigate = useNavigate();
  const onClickRestaurant = () => navigate(ROUTES.CREATE_RESTAURANT);
  const onClickPost = () => navigate(ROUTES.CREATE_POST);

  return (
    <Box p={4}>
      <Stack align={"center"}>
        <Box w={"95%"} maxW={"lg"}>
          <Stack gap={4}>
            <Box>
              <Heading mb={2}>Create</Heading>
              <Text textColor={"gray.500"}>
                Add a restaurant or write a review. Whichever it is, the best
                time to begin is now. So start your food blogging journey today!
              </Text>
            </Box>
            <Flex gap={"5"} direction={{ base: "column", md: "row" }}>
              <Button
                colorScheme="teal"
                variant="outline"
                w={{ base: "100%", md: "50%" }}
                h="150px"
                onClick={onClickRestaurant}
              >
                <Stack align={"center"}>
                  <IoMdHome size={"50px"} />
                  <Text>New Restaurant</Text>
                </Stack>
              </Button>
              <Button
                colorScheme="teal"
                variant="outline"
                w={{ base: "100%", md: "50%" }}
                h="150px"
                onClick={onClickPost}
              >
                <Stack align={"center"}>
                  <IoMdPaper size={"50px"} />
                  <Text>New Post</Text>
                </Stack>
              </Button>
            </Flex>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};

export default CreatePage;
