import {
  Alert,
  AlertIcon,
  Box,
  Center,
  HStack,
  Heading,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import {
  ActionMeta,
  AsyncSelect,
  MultiValue,
  Select,
} from "chakra-react-select";
import { useSearchParams } from "react-router-dom";

import { PageState } from "types/page_state";
import RankList from "components/RankList/RankList";
import { MiniPost } from "types/post";
import getRankedPosts from "services/posts/GetRankedPosts";
import { convertTagsStringToArray } from "helpers/ConvertTagsStringToArray";
import getTags from "services/tags/GetTags";
import Loading from "components/Loading/Loading";

const RankPageErrorComponent = () => (
  <Center>
    <Box p={"5"} maxW={"sm"}>
      <Stack align={"center"}>
        <Text as="b">Not Rankings Found</Text>
        <Text align={"justify"}>
          Our team is working hard to gather more data to get the rankings you
          are looking for. Thank you for your patience and understanding.
        </Text>
      </Stack>
    </Box>
  </Center>
);

const validLocationTags = ["north", "south", "east", "west", "central", "all"];

const isValidLocationTag = (locationTag: string): boolean =>
  validLocationTags.filter((val) => val === locationTag).length >= 1;

const RankPage = () => {
  const [rankedPost, setRankedPost] = useState<MiniPost[]>([] as MiniPost[]);
  const [state, setState] = useState<PageState>(PageState.InitialState);
  const [searchParams, setSearchParams] = useSearchParams();

  const locations = useMemo(() => {
    const tags = searchParams.get("locations") ?? "";
    return convertTagsStringToArray(tags).filter((val) => {
      return isValidLocationTag(val);
    });
  }, [searchParams]);

  const convertedTags = useMemo(() => {
    const tags = searchParams.get("tags") ?? "";
    return convertTagsStringToArray(tags);
  }, [searchParams]);

  const percentSimilar = searchParams.get("sm") ?? "";
  const smInt = parseInt(percentSimilar);
  const isValidNumber = !isNaN(smInt);
  const slideVal = isValidNumber ? smInt : 100;
  const [sliderValue, setSliderValue] = useState(slideVal);
  const [sliderLabelValue, setSliderLabelValue] = useState(slideVal);

  useEffect(() => {
    const helper = async () => {
      setState(PageState.LoadingState);
      const posts = await getRankedPosts(locations, convertedTags, sliderValue);

      if (Object.keys(posts).length === 0) {
        setState(PageState.ErrorState);
        return;
      }
      if (posts?.result?.length <= 0) {
        setState(PageState.ErrorState);
        return;
      }

      setRankedPost(posts.result);
      setState(PageState.SuccessState);
    };

    helper();
  }, [locations, convertedTags, sliderValue]);

  const tagOptions = convertedTags.map((val) => {
    return { value: val, label: val };
  });

  const changeSelectTagHandler = (
    newValue: MultiValue<{ value: string; label: string }>,
    actionMeta: ActionMeta<{ value: string; label: string }>
  ) => {
    const selectedValues = newValue.map((val) => {
      return val.value;
    });

    const params = {
      locations: locations.join(","),
      tags: selectedValues.join(","),
    };
    setSearchParams(params);
  };

  const changeSelectLocationHandler = (
    newValue: MultiValue<{ value: string; label: string }>,
    actionMeta: ActionMeta<{ value: string; label: string }>
  ) => {
    const selectedValues = newValue.map((val) => {
      return val.value;
    });

    const params = {
      locations: selectedValues.join(","),
      tags: convertedTags.join(","),
    };
    setSearchParams(params);
  };

  const locationOptions = [
    "all",
    "north",
    "south",
    "east",
    "west",
    "central",
  ].map((val) => {
    return { value: val, label: val };
  });

  const locationDefaultValue = locations.map((val) => {
    return { value: val, label: val };
  });

  if (state === PageState.LoadingState || state === PageState.InitialState) {
    return <Loading />;
  }

  const promiseOptions = (inputValue: string) =>
    new Promise<{ value: string; label: string }[]>(async (resolve) => {
      const getTagsResponse = await getTags(inputValue);
      const tags = getTagsResponse.tags;
      if (!tags) {
        resolve([]);
        return;
      }
      const tagsMapped = tags.map((val) => {
        return { value: val, label: val };
      });
      const tagsFiltered = tagsMapped.filter((i) =>
        i.label.toLowerCase().includes(inputValue.toLowerCase())
      );
      resolve(tagsFiltered);
    });

  return (
    <Box p={4} minH={"75vh"}>
      <Stack align={"center"}>
        <Box w={"95%"} maxW={"lg"}>
          <Box mb={2}>
            <Heading>Rankings</Heading>
          </Box>
          <Box mb={2}>
            <Alert status="info" borderRadius={5} size={"sm"}>
              <AlertIcon />
              You can now rank based on your location and the available tags!
            </Alert>
          </Box>
          <Stack spacing={4}>
            <HStack>
              <Text>Location:</Text>
              <Box w={"100%"}>
                <Select
                  isMulti
                  closeMenuOnSelect={false}
                  options={locationOptions}
                  defaultValue={locationDefaultValue}
                  onChange={changeSelectLocationHandler}
                  isClearable={false}
                  size={"md"}
                />
              </Box>
            </HStack>
            <HStack>
              <Text>Tags:</Text>
              <Box w={"100%"}>
                <AsyncSelect
                  isMulti
                  cacheOptions
                  defaultOptions
                  closeMenuOnSelect={false}
                  options={tagOptions}
                  defaultValue={tagOptions}
                  onChange={changeSelectTagHandler}
                  isClearable={false}
                  size={"md"}
                  loadOptions={promiseOptions}
                />
              </Box>
            </HStack>
            <HStack spacing={5} mt={5}>
              <Text as="b">Similarity:</Text>
              <Slider
                onChangeEnd={(val) => setSliderValue(val)}
                onChange={(val) => setSliderLabelValue(val)}
                defaultValue={sliderLabelValue}
                colorScheme="teal"
                mr={5}
              >
                <SliderMark
                  value={sliderLabelValue}
                  textAlign="center"
                  bg="teal"
                  color="white"
                  mt="-10"
                  ml="-5"
                  w="12"
                  borderRadius={5}
                >
                  {sliderLabelValue}%
                </SliderMark>
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb />
              </Slider>
            </HStack>

            {rankedPost.length > 0 && state !== PageState.ErrorState ? (
              <RankList rankedMiniPost={rankedPost} />
            ) : (
              RankPageErrorComponent()
            )}
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};

export default RankPage;
