import { Link, Stack, Text } from "@chakra-ui/react";
import { LatLngExpression, LatLngTuple } from "leaflet";
import { MapContainer, Marker, TileLayer, Tooltip } from "react-leaflet";

const PostMap = ({
  markerPosition,
  addressText,
  googleMapsLink,
  restaurantName,
}: {
  markerPosition: LatLngTuple;
  addressText: string;
  googleMapsLink: string;
  restaurantName: string;
}) => {
  const center: LatLngExpression = [
    markerPosition[0] + 0.002,
    markerPosition[1] - 0.003,
  ];

  return (
    <MapContainer center={center} zoom={15} scrollWheelZoom={false}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={markerPosition}>
        <Tooltip permanent interactive>
          <Stack spacing={0}>
            <Text as={"b"}>{restaurantName}</Text>
            {addressText.split("\n").map((val) => {
              return <Text key={val}>{val}</Text>;
            })}
            {googleMapsLink && (
              <Link href={googleMapsLink}>This is the google maps link</Link>
            )}
          </Stack>
        </Tooltip>
      </Marker>
    </MapContainer>
  );
};

export default PostMap;
