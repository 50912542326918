import axios from "axios";
import { Post } from "types/post";

// type the response
const getPost: (id: string | undefined) => Promise<Post> = async (
  id: string | undefined
): Promise<Post> => {
  if (!id) {
    return {} as Post;
  }
  let resPost: Post = {} as Post;

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BE_API}/api/post/${id}`
    );
    // const response = await fetch(`${process.env.REACT_APP_BE_API}/api/post/${id}`, {method:"POST", credentials:"include"})
    // const resJson = await response.json()
    resPost = response.data?.data?.info;
  } catch (err) {
    console.log(err);
    return {} as Post;
  }

  return resPost;
};

export default getPost;
