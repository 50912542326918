import {
  Box,
  Card,
  CardBody,
  Heading,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

const ScoringPage = () => (
  <Box p={4}>
    <Stack align={"center"}>
      <Box w={"95%"} maxW={"lg"}>
        <Stack>
          <Heading>What does f-score represent?</Heading>
          <Text>
            f-score is an overall rating given to a restaurant. Based on certain
            criteria with different weightage (as defined below), we calculate
            the f-score.
          </Text>
          <Heading as="h3" size="xl">
            Overall
          </Heading>
          <Text>The following are the weightage for each component</Text>
          <Text>Taste - 40%</Text>
          <Text>Service - 20%</Text>
          <Text>Value/Price - 20%</Text>
          <Text>Location / Accessibility - 10%</Text>
          <Text>Cleanliness - 10%</Text>
          <Text>
            Even with the score, we still encourage you to go and try it out for
            yourselves and make your own conclusions.
          </Text>
          <Card>
            <CardBody p={4}>
              <TableContainer>
                <Table size="sm" variant={"simple"}>
                  <Thead>
                    <Tr>
                      <Th>Score</Th>
                      <Th>Description</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td>
                        <Text color={"red"}>1 - 4</Text>
                      </Td>
                      <Td>Not Recommended</Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <Text color={"orange"}>5 - 7</Text>
                      </Td>
                      <Td>Can Try</Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <Text color={"green"}>8 - 10</Text>
                      </Td>
                      <Td>Recommended</Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>
            </CardBody>
          </Card>
          <Heading as="h3" size="xl">
            Components
          </Heading>
          <Text>
            Component scores are discrete and defined by their definition. Gaps
            between scores are reserved till there is a proper definition for
            them. This is used to express score in some perspective.
          </Text>
          <Heading as="h4" size="lg">
            Taste
          </Heading>
          <Text>
            Taste is subjective to each person and it is hard to quantify what
            taste good. However, we try to use an objective approach where we
            will define what the scorers likes and dislikes this may help you
            make a better decision.
          </Text>
          <Text color={"red"}>0 - Will not eat even if starving.</Text>
          <Text color={"red"}>3 - Taste Bad. Will not eat again.</Text>
          <Text color={"orange"}>5 - Taste Decent. Will not eat alone.</Text>
          <Text color={"orange"}>7 - Taste Decent. Still will eat.</Text>
          <Text color={"green"}>8 - Taste Amazing. Will eat if nearby.</Text>
          <Text color={"green"}>
            10 - Taste Amazing. Will make a special trip.
          </Text>
          <Heading as="h4" size="lg">
            Service
          </Heading>
          <Text>
            The service standards of a place is also important. If the server is
            rude or wait times are long, the dining experience will not be
            enjoyable as well.
          </Text>
          <Text color={"red"}>0 - Rude and Long Wait times for food.</Text>
          <Text color={"red"}>3 - Long Wait times for food.</Text>
          <Text color={"orange"}>
            7 - Good service and decent wait times for food.
          </Text>
          <Text color={"green"}>10 - Amazing service.</Text>
          <Heading as="h4" size="lg">
            Price
          </Heading>
          <Text>
            We are always looking for good value. Value does not equal cheap.
            Here, we quantify the value proportionate to the item sold. It is
            difficult to expect Japanese sashimi to have the same price as
            chicken rice.
          </Text>
          <Text color={"red"}>1 - Expensive.</Text>
          <Text color={"red"}>3 - Slightly Expensive.</Text>
          <Text color={"orange"}>5 - Average.</Text>
          <Text color={"orange"}>7 - Affordable.</Text>
          <Text color={"green"}>10 - Very Affordable.</Text>
          <Heading as="h4" size="lg">
            Accessibility
          </Heading>
          <Text>
            Anywhere is accessible in Singapore. However, some places are more
            accessible than others. While we believe that good food is worth
            traveling for, if the store is at a accessible location, it would
            make our lives easier.
          </Text>
          <Text>
            This may be relative to everyone. So we would reference it to a
            person staying near Toa Payoh MRT station.
          </Text>
          <Text>
            Location scoring is discrete and well defined below. So there is no
            in between.
          </Text>
          <Text color={"red"}>
            0 - Car Preferred (Parking expensive or hard to find)
          </Text>
          <Text color={"red"}>
            1 - Car Preferred (Might need to change bus or walk very far).
          </Text>
          <Text color={"orange"}>5 - Bus Only (No MRT Station nearby).</Text>
          <Text color={"green"}>10 - Near MRT (Near an MRT Station).</Text>
          <Heading as="h4" size="lg">
            Cleanliness
          </Heading>
          <Text>
            Last but not least, anywhere that deals with food should be clean.
            As such this is an important metric
          </Text>
          <Text color={"red"}>0 - Bug found in food.</Text>
          <Text color={"red"}>1 - Plates slightly dirty.</Text>
          <Text color={"orange"}>5 - Animals running around.</Text>
          <Text color={"orange"}>7 - Table dirty.</Text>
          <Text color={"green"}>10 - Pristine.</Text>
        </Stack>
      </Box>
    </Stack>
  </Box>
);

export default ScoringPage;
