import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { FieldErrors, UseFormRegister } from "react-hook-form";

const ExcerptInput = ({
  errors,
  register,
}: {
  errors: FieldErrors<any>;
  register: UseFormRegister<any>;
}) => {
  return (
    <FormControl isInvalid={errors?.excerpt && true}>
      <FormLabel>Excerpt</FormLabel>
      <Input
        type="text"
        id="excerpt"
        {...register("excerpt", {
          required: "This is required",
          minLength: {
            value: 4,
            message: "Minimum length should be 4",
          },
        })}
      />
      <FormErrorMessage>{errors.excerpt?.message?.toString()}</FormErrorMessage>
    </FormControl>
  );
};

export default ExcerptInput;
