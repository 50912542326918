import { Box, Center, HStack, Heading, Image, Stack } from "@chakra-ui/react";
import { useEffect, useState } from "react";

import PostCard from "components/PostCard/PostCard";
import { MiniPost } from "types/post";
import { PageState } from "types/page_state";
import getHomePosts from "services/posts/GetHomePosts";
import ErrorOccurred from "components/Errors/NotFound";
import RestaurantCard from "components/RestaurantCard/RestaurantCard";
import { Restaurant } from "types/restaurant";
import Loading from "components/Loading/Loading";
import { HOMEPAGE_BANNER_IMAGE_LINK } from "constants/constants";

const HomePage = () => {
  const [listOfPlaces, setListOfPlaces] = useState<MiniPost[]>([]);
  const [recommended, setRecommended] = useState<MiniPost>({} as MiniPost);
  const [restaurants, setRestaurants] = useState<Restaurant[]>([]);
  const [state, setState] = useState<PageState>(PageState.InitialState);
  const [heroImageLoaded, setHeroImageLoaded] = useState(false);

  useEffect(() => {
    const helper = async () => {
      setState(PageState.LoadingState);
      const posts = await getHomePosts();

      if (Object.keys(posts).length === 0) {
        setState(PageState.ErrorState);
        return;
      }

      setListOfPlaces(posts.recent);
      setRecommended(posts.recommended);
      if (posts.restaurants) {
        setRestaurants(posts.restaurants);
      }
      setState(PageState.SuccessState);
    };

    helper();
  }, []);

  if (state === PageState.LoadingState) {
    return <Loading />;
  }

  if (state === PageState.ErrorState) {
    return (
      <ErrorOccurred text="Oops something is not right. We are working to fix it. Thank you for your patience and understanding" />
    );
  }

  if (state === PageState.SuccessState) {
    return (
      <Box>
        <Center
          w={"100%"}
          h={"35vh"}
          textAlign={"center"}
          overflow={"hidden"}
          position={"relative"}
        >
          <Image
            src={HOMEPAGE_BANNER_IMAGE_LINK}
            filter={"brightness(30%)"}
            h={"100%"}
            objectFit={"cover"}
            onLoad={() => setHeroImageLoaded(true)}
          />
          {heroImageLoaded && (
            <Heading
              w={"95%"}
              maxW={"lg"}
              transform={"auto"}
              position={"absolute"}
              translateX="-50%"
              translateY="-50%"
              top={"50%"}
              left={"50%"}
              textShadow={"1px 1px gray"}
              color={"white"}
            >
              Local Recommendations At Your Fingertips
            </Heading>
          )}
        </Center>
        <Stack p={4} align={"center"}>
          <Box w={"95%"} maxW={"lg"}>
            <Box mb={5}>
              <Heading>Recommended</Heading>
            </Box>
            {
              <PostCard
                key={recommended.id}
                id={recommended.id}
                name={recommended.name}
                description={recommended.excerpt}
                created_at={recommended.created_at}
                score={recommended.score}
              />
            }
            <Box my={5}>
              <Heading>Restaurants</Heading>
            </Box>
            <Box>
              <HStack overflowX="auto" pb={2}>
                {restaurants.map((val) => {
                  return <RestaurantCard key={val.id} restaurant={val} />;
                })}
              </HStack>
            </Box>

            <Box mb={5} mt={5}>
              <Heading>Latest Post</Heading>
            </Box>
            <Stack>
              {listOfPlaces.map((val) => (
                <PostCard
                  key={val.id}
                  id={val.id}
                  name={val.name}
                  description={val.excerpt}
                  created_at={val.created_at}
                  score={val.score}
                />
              ))}
            </Stack>
          </Box>
        </Stack>
      </Box>
    );
  }

  return null;
};

export default HomePage;
