interface GetTagsResponse {
    tags: string[]
}

const GetTagsApi = `${process.env.REACT_APP_BE_API}/api/tag/search`

const getTags: (query: string) => Promise<GetTagsResponse> = async (query: string): Promise<GetTagsResponse> => {
    
    let resTags: GetTagsResponse = {} as GetTagsResponse
    try {
        const response = await fetch(GetTagsApi, {method:"POST", body:JSON.stringify({query:query})})
        const resJson = await response.json()
        resTags = resJson.data
    } catch (err) {
        console.log(err)
        return {} as GetTagsResponse
    }

    return resTags
}

export default getTags