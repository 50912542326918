import { Alert, AlertIcon, Text } from "@chakra-ui/react";

/*
Daily hours is a string split by an escape newline character.
Target is to use it as a sunday to saturday chunk of text
*/
const PostOperatingHours = ({
  dailyHours,
  specialMessage,
}: {
  dailyHours: string;
  specialMessage: string;
}) => {
  if (!dailyHours) {
    return <Text>No Information Available</Text>;
  }
  return (
    <>
      {dailyHours.split("\n").map((val) => {
        return <Text key={val}>{val}</Text>;
      })}
      {specialMessage.length > 0 && (
        <Alert status="error" borderRadius={5}>
          <AlertIcon />
          <Text>{specialMessage}</Text>
        </Alert>
      )}
    </>
  );
};

export default PostOperatingHours;
