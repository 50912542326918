import axios from "axios";

const uploadImage = async (file: any): Promise<string> => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("upload_preset", "public_limited");

  const uninterceptedAxiosInstance = axios.create();
  const data = await uninterceptedAxiosInstance.post(
    "https://api.cloudinary.com/v1_1/dskpstidt/image/upload",
    formData
  );

  console.log(data.data);

  return data.data["secure_url"];
};

export default uploadImage;
