import axios from "axios";
import { GetRestaurantResponse } from "types/getRestaurantResponse";

const getRestaurant: (
  id: string | undefined
) => Promise<GetRestaurantResponse> = async (
  id: string | undefined
): Promise<GetRestaurantResponse> => {
  if (!id) {
    return {} as GetRestaurantResponse;
  }

  let response;

  try {
    response = await axios.post(
      `${process.env.REACT_APP_BE_API}/api/restaurant/${id}`
    );
  } catch (err) {
    console.log(err);
    return {} as GetRestaurantResponse;
  }

  const resRes: GetRestaurantResponse = response?.data?.data;

  return resRes;
};

export default getRestaurant;
