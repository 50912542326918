 /*
Based on the score provided, a color will be chosen to show on the card.
3 shades are shown
Red, Orange Green (similar to traffic light)
Red <= 4
4 < Orange <= 7
7 < Green <= 10
*/
export const convertTagsStringToArray = (tags: string): string[] => {
    if (tags.length === 0) {
        return []
    }
    return tags.split(",")
  }