import {
  Box,
  Card,
  CardBody,
  HStack,
  Heading,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Tag,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import { CheckIcon, DeleteIcon, InfoIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { BiDotsVerticalRounded } from "react-icons/bi";

import OverallFScore from "components/PostPage/F_score/OverallFScore";
import { generateRestaurantWithIdRoute } from "helpers/generateRoutes";

const RestaurantListCard = ({
  id,
  name,
  postScore,
  isOpen,
  onMoveHandler,
  onRemoveHandler,
}: {
  name: string;
  id: string;
  postScore: number;
  isOpen: boolean;
  onMoveHandler: () => void;
  onRemoveHandler: () => void;
}) => {
  const [moveRight, setMoveRight] = useState(true);
  const navigate = useNavigate();
  const MotionCard = motion(Card);

  const animations1 = {
    animate: { opacity: 1, scale: 1, x: 0 },
    exit: { opacity: 0, scale: 0, x: moveRight ? 1000 : -1000 },
  };

  const onRemoveClick = () => {
    setMoveRight(false);
    onRemoveHandler();
  };

  const onNextClick = () => {
    setMoveRight(true);
    onMoveHandler();
  };

  return (
    <MotionCard {...animations1} layout>
      <CardBody>
        <Stack>
          <HStack justifyContent={"space-between"}>
            <Heading fontSize={"xl"} noOfLines={2}>
              {name}
            </Heading>
            <Menu>
              <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<BiDotsVerticalRounded />}
                variant="ghost"
                size={"lg"}
              />
              <MenuList>
                <MenuItem icon={<DeleteIcon />} onClick={onRemoveClick}>
                  Remove Item
                </MenuItem>
              </MenuList>
            </Menu>
          </HStack>
          <Box>
            {isOpen ? (
              <Tag colorScheme="teal">Open</Tag>
            ) : (
              <Tag colorScheme="red">Closed</Tag>
            )}
          </Box>
          <Text noOfLines={2}>
            Lorem ipsum is random text that might appear in this area. The card
            looks quite empty. so maybe we could add something?
          </Text>
          <OverallFScore postScore={postScore} hideInfoButton={true} />
          {/* <HStack justify={"left"}>
            <IconButton
              variant="ghost"
              colorScheme="red"
              aria-label="completed"
              icon={<BiDotsVerticalRounded />}
              onClick={removeItemHandler}
            />
          </HStack> */}
          <HStack justify={"right"}>
            <IconButton
              variant="outline"
              colorScheme="teal"
              aria-label="info"
              icon={<InfoIcon />}
              onClick={() => navigate(generateRestaurantWithIdRoute(id))}
            />
            <IconButton
              variant="outline"
              colorScheme="teal"
              aria-label="completed"
              icon={<CheckIcon />}
              onClick={onNextClick}
            />
          </HStack>
        </Stack>
      </CardBody>
    </MotionCard>
  );
};
export default RestaurantListCard;
