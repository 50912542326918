import {
  HStack,
  IconButton,
  LinkBox,
  LinkOverlay,
  Text,
} from "@chakra-ui/react";

import { chooseScoreColor } from "helpers/ScoreColor";

const OverallFScore = ({
  postScore,
  hideInfoButton,
}: {
  postScore: number;
  hideInfoButton?: boolean;
}) => {
  return (
    <HStack>
      <Text as="b">f-score: </Text>
      <Text as="b" color={chooseScoreColor(postScore)}>
        {postScore ? `${postScore}/10` : "No Data"}
      </Text>
      {!hideInfoButton && (
        <LinkBox>
          <LinkOverlay href={`/scoring`}>
            <IconButton
              isRound={true}
              variant="solid"
              colorScheme="teal"
              aria-label="Done"
              fontSize="10px"
              icon={<Text>i</Text>}
              m={1}
              size={"xs"}
            />
          </LinkOverlay>
        </LinkBox>
      )}
    </HStack>
  );
};

export default OverallFScore;
