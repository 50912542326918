import { Box, Heading, Stack, Text } from "@chakra-ui/react";

const ErrorOccurred = ({ text }: { text: string }) => {
  return (
    <Box p={4} mt={"5rem"}>
      <Stack align="center">
        <Heading size="lg">Oops</Heading>
        <Text align="center">{text}</Text>
      </Stack>
    </Box>
  );
};

export default ErrorOccurred;
