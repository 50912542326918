import { useState } from "react";
import {
  Box,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightAddon,
  Select,
  Stack,
} from "@chakra-ui/react";
import { Search2Icon } from "@chakra-ui/icons";

const SearchBar = ({
  onSearch,
  fill,
  sortBy,
}: {
  onSearch: (searchValue: string, sortBy: string) => void;
  fill: string;
  sortBy: string;
}) => {
  const [searchValue, setSearchValue] = useState(fill);
  const [sortByValue, setSortByValue] = useState(sortBy);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const sortByChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSortByValue(event.target.value);
    onSearch(searchValue, event.target.value);
  };

  const onClickHandler = () => {
    if (searchValue.length <= 0) {
      return;
    }
    onSearch(searchValue, sortByValue);
  };
  return (
    <Box>
      <Stack>
        <InputGroup borderRadius={5} size="sm">
          <InputLeftElement
            pointerEvents="none"
            children={<Search2Icon color="gray.600" />}
          />
          <Input
            type="text"
            placeholder="Search..."
            border="1px solid #949494"
            borderRadius={10}
            value={searchValue}
            onChange={handleChange}
          />
          <InputRightAddon p={0} border="none">
            <Button
              size="sm"
              borderLeftRadius={0}
              borderRightRadius={10}
              border="1px solid #949494"
              onClick={onClickHandler}
            >
              Search
            </Button>
          </InputRightAddon>
        </InputGroup>
        <Select
          placeholder="Sort By"
          size="sm"
          value={sortByValue}
          onChange={sortByChange}
        >
          <option value="as">Ascending Score</option>
          <option value="ds">Descending Score</option>
        </Select>
      </Stack>
    </Box>
  );
};

export default SearchBar;
