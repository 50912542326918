import axios from "axios";
import { MoveToListRequest } from "types/moveToListRequest";
import { MoveToListResponse } from "types/moveToListResponse";

const moveToList = async (
  req: MoveToListRequest
): Promise<MoveToListResponse> => {
  let response;

  try {
    response = await axios.post(
      `${process.env.REACT_APP_BE_API}/api/list/move`,
      req
    );
  } catch (err) {
    console.log(err);
    return {} as MoveToListResponse;
  }

  const resRes = response.data.data;
  return resRes;
};

export default moveToList;
