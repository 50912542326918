import { useLayoutEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { Grid } from "@chakra-ui/react";

import NavBar from "components/NavBar/NavBar";
import SearchPage from "pages/SearchPage";
import HomePage from "pages/HomePage";
import NotFound from "pages/NotFound";
import PostPage from "pages/PostPage";
import Footer from "components/Footer/footer";
import RecommendPage from "pages/RecommendPage";
import ScoringPage from "pages/ScoringPage";
import RankPage from "pages/RankPage";
import AboutPage from "pages/AboutPage";
import CreatePostPageDemo from "pages/CreatePostPageDemo";
import AuthorPage from "pages/AuthorPage";
import AuthPage from "pages/AuthPage";
import { AuthProvider } from "hooks/useAuth";
import RequireAuth from "middleware/RequireAuth";
import { setupInterceptors } from "services/axios";
import RestaurantPage from "pages/RestaurantPage";
import RestaurantGroupPage from "pages/RestaurantGroupPage";
import ScrollToTop from "middleware/ScrollToTop";
import CreatePage from "pages/CreatePage";
import CreateRestaurantPage from "pages/CreateRestaurantPage";
import ListPage from "pages/ListPage";
import { ROUTES } from "constants/routes";

const paths = [
  { name: "Home", path: ROUTES.HOME },
  { name: "Explore", path: ROUTES.EXPLORE },
  { name: "Ranking", path: ROUTES.RANKING },
  { name: "Recommend", path: ROUTES.RECOMMEND },
];

function App() {
  const navigate = useNavigate();
  const location = useLocation();

  // will have performance impact?

  useLayoutEffect(() => {
    console.log("rerenderingggggg");
    setupInterceptors(navigate, location);
  }, [navigate, location]);

  return (
    <ScrollToTop>
      <AuthProvider>
        <Grid minHeight="100vh" templateRows="max-content 1fr max-content">
          <NavBar links={paths} />
          <Routes>
            <Route path={ROUTES.HOME} element={<HomePage />} />
            <Route path={ROUTES.AUTH} element={<AuthPage />} />
            <Route path={ROUTES.EXPLORE} element={<SearchPage />} />
            <Route path={ROUTES.RECOMMEND} element={<RecommendPage />} />
            <Route path={ROUTES.SCORING} element={<ScoringPage />} />
            <Route path={ROUTES.RANKING} element={<RankPage />} />
            <Route
              path={ROUTES.CREATE}
              element={
                <RequireAuth>
                  <CreatePage />
                </RequireAuth>
              }
            />
            <Route
              path={ROUTES.CREATE_POST}
              element={
                <RequireAuth>
                  <CreatePostPageDemo />
                </RequireAuth>
              }
            />
            <Route
              path={ROUTES.CREATE_RESTAURANT}
              element={
                <RequireAuth>
                  <CreateRestaurantPage />
                </RequireAuth>
              }
            />
            <Route
              path={ROUTES.LIST}
              element={
                <RequireAuth>
                  <ListPage />
                </RequireAuth>
              }
            />
            <Route path={ROUTES.POST_WITH_ID} element={<PostPage />} />
            <Route path={ROUTES.ABOUT} element={<AboutPage />} />
            <Route path={ROUTES.AUTHOR_WITH_ID} element={<AuthorPage />} />
            <Route
              path={ROUTES.RESTAURANT_WITH_ID}
              element={<RestaurantPage />}
            />
            <Route
              path={ROUTES.GROUP_WITH_ID}
              element={<RestaurantGroupPage />}
            />
            <Route path="*" element={<NotFound />} />
          </Routes>

          <Footer />
        </Grid>
      </AuthProvider>
    </ScrollToTop>
  );
}

export default App;
