import { Box, Heading, Image, Stack, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { format } from "date-fns";
import { LatLngExpression } from "leaflet";

import { Post, ScoreBreakdown } from "types/post";
import ImageCarousel from "components/ImageCarousel/ImageCarousel";
import getPost from "services/posts/GetPost";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import { PageState } from "types/page_state";
import { ScoreBreakdownChart } from "components/ScoreBreakdown/ScoreBreakdown";
import OverallFScore from "components/PostPage/F_score/OverallFScore";
import PostMap from "components/PostMap/PostMap";
import PostOperatingHours from "components/PostPage/PostOperatingHours/PostOperatingHours";
import WrittenOn from "components/PostPage/WrittenOn/WrittenOn";
import RankDisplay from "components/PostPage/RankDisplay/RankDisplay";
import PostDescription from "components/PostPage/PostDescription/PostDescription";
import RecommendationView from "components/PostPage/RecommendationView/RecommendationView";
import TagView from "components/PostPage/TagView/TagView";
import AuthorView from "components/PostPage/AuthorView/AuthorView";
import RestaurantView from "components/PostPage/RestaurantView/RestaurantView";

const mergeTags = (
  tags: string[] | undefined,
  locationTags: string[] | undefined
): string[] => {
  var returnTags: string[] = [];
  if (tags) {
    returnTags = returnTags.concat(tags);
  }

  if (locationTags) {
    returnTags = returnTags.concat(locationTags);
  }

  return returnTags;
};

const PostPage = () => {
  let { id } = useParams();
  const [post, setPost] = useState<Post | undefined>();
  const [state, setState] = useState<PageState>(PageState.InitialState);

  const postHasImages = post?.images?.length;
  const postImages = post?.images ? post.images : [];
  const mergedTags = mergeTags(post?.tags, post?.location_tags);

  useEffect(() => {
    const helper = async () => {
      setState(PageState.LoadingState);
      const post = await getPost(id);

      // find a better way to check for error
      if (!post) {
        setState(PageState.ErrorState);
        return;
      }
      if (Object.keys(post).length === 0) {
        setState(PageState.ErrorState);
        return;
      }

      setPost(post);
      setState(PageState.SuccessState);
    };

    helper();
  }, [id]);

  let createdDate = "";
  if (post?.created_at) {
    createdDate = format(post?.created_at * 1000, "dd/MM/yyyy");
  }

  let updatedDate = "";
  if (post?.updated_at) {
    updatedDate = format(post?.updated_at * 1000, "dd/MM/yyyy");
  }

  let postScore = post ? post.score : 0;
  let postRanking = post ? (post.rank_info ? post.rank_info : []) : [];
  let postScoreBreakdown = post ? post.score_breakdown : ({} as ScoreBreakdown);
  let postName = post ? post.name : "Have a guess";
  const position: LatLngExpression = post
    ? [post?.location_details.lat, post?.location_details.long]
    : [1, 103];
  const address = post ? post.location_details.address : "";
  const googleMapsLink = post ? post.location_details.google_link : "";

  if (state === PageState.ErrorState) {
    return <Navigate to={"/notfound"} />;
  }

  if (state === PageState.LoadingState || state === PageState.InitialState) {
    return (
      <>
        <Box mt={"20vh"} mb={"20vh"}>
          <Stack align={"center"}>
            <LoadingSpinner />
            <Text>isLoading</Text>
          </Stack>
        </Box>
      </>
    );
  }

  const openingHours = post
    ? post.operating_hours.daily_hours
    : "No Information Available";
  const specialDetails = post ? post.operating_hours.special_details : "";

  return (
    <Box p={4}>
      <Stack align={"center"}>
        <Box w={"95%"} maxW={"lg"}>
          <Stack>
            <Heading size="lg" my="2">
              {`${post?.name}`}
            </Heading>
            <WrittenOn updatedDate={updatedDate} createdDate={createdDate} />
            <AuthorView
              username={post?.author ? post.author.username : "Anonymous"}
              link={post?.author ? `/author/${post.author.id}` : "/"}
            />
            <RestaurantView
              name={post?.restaurant ? post.restaurant.name : "Some Restaurant"}
              link={
                post?.restaurant ? `/restaurant/${post.restaurant.id}` : "/"
              }
            />
            <OverallFScore postScore={postScore} />
            <Text fontSize={"lg"}>TLDR: {post?.excerpt}</Text>
            <Image src={post?.header_image} mb={15} />
            <PostDescription
              description={post?.description ? post.description : []}
            />
            <RecommendationView
              recommendedItem={
                post?.recommended_items ? post.recommended_items : []
              }
            />
            <RankDisplay postRanking={postRanking} />
            {postHasImages ? (
              <Box mt={5}>
                {postImages.length === 1 ? (
                  <Heading>Image</Heading>
                ) : (
                  <Heading>Images</Heading>
                )}
                <ImageCarousel images={postImages} />
              </Box>
            ) : (
              <></>
            )}
            <Box mt={5}>
              <Heading>Score Breakdown</Heading>
              <Box>
                <OverallFScore postScore={postScore} />
              </Box>
              <ScoreBreakdownChart breakdown={postScoreBreakdown} />
            </Box>
            <Heading size={"lg"}>Location</Heading>
            <Box h={"350px"}>
              <PostMap
                markerPosition={position}
                addressText={address}
                googleMapsLink={googleMapsLink}
                restaurantName={postName}
              />
            </Box>
            <Heading size={"lg"}>Operating Hours</Heading>
            <PostOperatingHours
              dailyHours={openingHours}
              specialMessage={specialDetails}
            />
            <TagView mergedTags={mergedTags} />
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};

export default PostPage;
