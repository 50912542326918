import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import { Button, HStack, Input, Text } from "@chakra-ui/react";
import { Control, UseFormWatch, useFieldArray } from "react-hook-form";

const AddHours = ({
  day,
  register,
  control,
  watch,
}: {
  day: string;
  register: any;
  watch: UseFormWatch<any>;
  control: Control<any>;
}) => {
  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: `operating_hours.${day}.hours`, // unique name for your Field Array
  });

  const onClickAdd = (day: string) => {
    return () => {
      append({
        opening: "08:00",
        closing: "18:00",
      });
    };
  };

  const watchIsClosed = watch(`operating_hours.${day}.is_closed`);

  const onClickMinus = (index: number) => {
    return () => {
      console.log(control._formValues.operating_hours.monday.is_closed);
      remove(index);
    };
  };

  return (
    <>
      {fields.map((field, index) => {
        return (
          <HStack key={field.id + "hours"}>
            <Input
              key={field.id + "opening"} // important to include key with field's id
              isDisabled={watchIsClosed}
              {...register(`operating_hours.${day}.hours.${index}.opening`)}
            />
            <Text> - </Text>
            <Input
              key={field.id + "closing"} // important to include key with field's id
              isDisabled={watchIsClosed}
              {...register(`operating_hours.${day}.hours.${index}.closing`)}
            />
            {
              <Button
                onClick={onClickMinus(index)}
                isDisabled={fields.length === 1 || watchIsClosed}
              >
                <MinusIcon />
              </Button>
            }
          </HStack>
        );
      })}
      <Button onClick={onClickAdd(day)} isDisabled={watchIsClosed}>
        <AddIcon />
      </Button>
    </>
  );
};

export default AddHours;
