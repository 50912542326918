import { FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/react";
import { FieldErrors, UseFormSetValue } from "react-hook-form";

import ImageUploadSingle from "components/ImageUpload/ImageUploadSingle";

const HeaderImageUpload = ({
  errors,
  formValueKey,
  setValue,
}: {
  errors: FieldErrors<any>;
  formValueKey: string;
  setValue: UseFormSetValue<any>;
}) => {
  return (
    <FormControl isInvalid={errors?.header_image && true}>
      <FormLabel>Header Image</FormLabel>
      <ImageUploadSingle formValueKey={formValueKey} setValue={setValue} />
      <FormErrorMessage>
        {errors.header_image?.message?.toString()}
      </FormErrorMessage>
    </FormControl>
  );
};

export default HeaderImageUpload;
