import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text,
  Textarea,
  NumberInput,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInputField,
  NumberInputStepper,
  HStack,
  Flex,
  Switch,
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { useState } from "react";
import {
  Controller,
  FieldValues,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { useNavigate } from "react-router-dom";

import AddHours from "components/CreateRestaurantPage/FormComponents/AddHours";
import { CreateRestaurantRequest } from "types/createRestaurantRequest";
import createNewRestaurant from "services/restaurant/CreateRestaurant";
import ImageUploadSingle from "components/ImageUpload/ImageUploadSingle";
import { generateRestaurantWithIdRoute } from "helpers/generateRoutes";

interface OperatingHours {
  is_closed: boolean;
  hours: { opening: string; closing: string }[];
}
interface FormType {
  name: string;
  header_image: string;
  address: string;
  long: number;
  lat: number;
  google_link: string;
  special_details: string;
  location_tags: string[];
  operating_hours: {
    monday: OperatingHours;
    tuesday: OperatingHours;
    wednesday: OperatingHours;
    thursday: OperatingHours;
    friday: OperatingHours;
    saturday: OperatingHours;
    sunday: OperatingHours;
  };
}

const defaultFormValues: FormType = {
  name: "",
  header_image: "",
  address: "",
  long: 103,
  lat: 1,
  google_link: "",
  special_details: "",
  location_tags: [],
  operating_hours: {
    monday: {
      is_closed: false,
      hours: [
        {
          opening: "08:00",
          closing: "18:00",
        },
      ],
    },
    tuesday: {
      is_closed: false,
      hours: [
        {
          opening: "08:00",
          closing: "18:00",
        },
      ],
    },
    wednesday: {
      is_closed: false,
      hours: [
        {
          opening: "08:00",
          closing: "18:00",
        },
      ],
    },
    thursday: {
      is_closed: false,
      hours: [
        {
          opening: "08:00",
          closing: "18:00",
        },
      ],
    },
    friday: {
      is_closed: false,
      hours: [
        {
          opening: "08:00",
          closing: "18:00",
        },
      ],
    },
    saturday: {
      is_closed: false,
      hours: [
        {
          opening: "08:00",
          closing: "18:00",
        },
      ],
    },
    sunday: {
      is_closed: false,
      hours: [
        {
          opening: "08:00",
          closing: "18:00",
        },
      ],
    },
  },
};
const CreateRestaurantPage = () => {
  const {
    control,
    handleSubmit,
    register,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({ defaultValues: defaultFormValues });

  const navigate = useNavigate();

  const [submitError, setSubmitError] = useState("");

  const onSubmit: SubmitHandler<FieldValues> = async (values) => {
    const formattedValues: CreateRestaurantRequest = {
      name: values.name,
      header_image: values.header_image,
      is_hidden: false,
      address: values.address,
      long: values.long,
      lat: values.lat,
      google_link: values.google_link,
      location_tags: values.location_tags.map((val: any) => val.value),
      operating_hours: {
        special_details: values.special_details,
        daily_hours: Object.keys(values.operating_hours).map((key) => {
          return {
            is_closed: values.operating_hours[key].is_closed,
            is_24_hrs: false,
            hours: values.operating_hours[key].hours.map(
              (val: { opening: string; closing: string }) => {
                return {
                  opening: val.opening,
                  closing: val.closing,
                };
              }
            ),
          };
        }),
      },
    };

    const isSuccess = await createNewRestaurant(formattedValues);
    if (isSuccess?.id) {
      navigate(generateRestaurantWithIdRoute(isSuccess.id));
      return;
    }

    setSubmitError("Error occurred when creating new restaurant");
    console.log(formattedValues);
  };

  const locationOptions = ["north", "south", "east", "west", "central"].map(
    (val) => {
      return { value: val, label: val };
    }
  );

  const dayOfWeek: string[] = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];

  return (
    <Box p={4}>
      <Stack align={"center"}>
        <Box w={"95%"} maxW={"lg"}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack>
              <Heading>Details</Heading>
              <FormControl isInvalid={errors?.name && true}>
                <FormLabel>Restaurant Name</FormLabel>
                <Input
                  type="text"
                  id="name"
                  {...register("name", {
                    required: "This is required",
                    minLength: {
                      value: 4,
                      message: "Minimum length should be 4",
                    },
                  })}
                />
                <FormErrorMessage>
                  <>{errors.name?.message}</>
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors?.header_image && true}>
                <FormLabel>Restaurant Image</FormLabel>
                <ImageUploadSingle
                  setValue={setValue}
                  formValueKey="header_image"
                />
                <FormErrorMessage>
                  {errors.header_image?.message?.toString()}
                </FormErrorMessage>
              </FormControl>

              <Heading>Location Info</Heading>
              <FormControl isInvalid={!!errors?.address}>
                <FormLabel>Address</FormLabel>
                <Textarea
                  placeholder="address"
                  rows={3}
                  {...register("address", {
                    required: "This is required",
                  })}
                />
                <FormErrorMessage>
                  {errors.address?.message?.toString()}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={!!errors?.google_link}>
                <FormLabel>Google Link</FormLabel>
                <Input
                  type="text"
                  {...register("google_link", {
                    required: "This is required",
                  })}
                />
                <FormHelperText>Google link to the restaurant</FormHelperText>
                <FormErrorMessage>
                  {errors.google_link?.message?.toString()}
                </FormErrorMessage>
              </FormControl>
              <Controller
                control={control}
                name={"location_tags"}
                rules={{ required: "Please enter at least one tag." }}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, error },
                }) => (
                  <FormControl isInvalid={invalid}>
                    <FormLabel>LocationTags</FormLabel>
                    <Select
                      isMulti
                      closeMenuOnSelect={false}
                      options={locationOptions as any}
                      onChange={onChange}
                      isClearable={false}
                      size={"md"}
                      value={value}
                      onBlur={onBlur}
                      ref={ref}
                    />
                    <FormHelperText>
                      Tags that describe where the restaurant is located
                    </FormHelperText>
                    <FormErrorMessage>
                      {error && error.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
              <Controller
                control={control}
                name={"long"}
                render={({ field: { onChange, value, name } }) => (
                  <FormControl>
                    <FormLabel>Longitude</FormLabel>
                    <NumberInput
                      precision={9}
                      step={0.00000001}
                      defaultValue={103}
                      onChange={(s, n) => onChange(n)}
                      value={value}
                      name={name}
                    >
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  </FormControl>
                )}
              />
              <Controller
                control={control}
                name={"lat"}
                render={({ field: { onChange, value, name } }) => (
                  <FormControl>
                    <FormLabel>Latitude</FormLabel>
                    <NumberInput
                      precision={9}
                      step={0.00000001}
                      defaultValue={1}
                      onChange={(s, n) => onChange(n)}
                      value={value}
                      name={name}
                    >
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  </FormControl>
                )}
              />
              <Heading>Operating Hours</Heading>
              <FormControl isInvalid={!!errors?.special_details}>
                <FormLabel>Special Details</FormLabel>
                <Input type="text" {...register("special_details")} />
                <FormHelperText>
                  Special details about operating hours
                </FormHelperText>
                <FormErrorMessage>
                  {errors.special_details?.message?.toString()}
                </FormErrorMessage>
              </FormControl>

              {dayOfWeek.map((day) => (
                <Box w={"100%"} key={day}>
                  <Stack>
                    <Box w={"100%"} mb={2}>
                      <Flex direction={"row"} justifyContent={"space-between"}>
                        <FormLabel fontSize={"lg"} fontWeight={"bold"}>
                          {day}
                        </FormLabel>
                        <Controller
                          control={control}
                          name={`operating_hours.${day}.is_closed` as any}
                          render={({ field: { onChange, value, name } }) => (
                            <HStack>
                              <Text>is closed?</Text>
                              <Switch
                                name={name}
                                colorScheme="teal"
                                size={"lg"}
                                onChange={onChange}
                                isChecked={value}
                              />
                            </HStack>
                          )}
                        />
                      </Flex>
                    </Box>
                    <AddHours
                      day={day}
                      register={register}
                      control={control}
                      watch={watch}
                    />
                  </Stack>
                </Box>
              ))}
            </Stack>
            {submitError.length > 0 && (
              <Text textColor={"red.500"}>{submitError}</Text>
            )}
            <Button
              mt={4}
              colorScheme="teal"
              isLoading={isSubmitting}
              type="submit"
              isDisabled={!errors}
            >
              Create
            </Button>
          </form>
        </Box>
      </Stack>
    </Box>
  );
};

export default CreateRestaurantPage;
