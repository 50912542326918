import axios from "axios";
import { CreatePostRequest } from "types/createPostRequest";
import { CreatePostResponse } from "types/createPostResponse";

const createNewPost: (
  req: CreatePostRequest
) => Promise<CreatePostResponse> = async (
  req: CreatePostRequest
): Promise<CreatePostResponse> => {
  // send axios with cookie also

  let response;

  try {
    response = await axios.post(
      `${process.env.REACT_APP_BE_API}/api/post/create`,
      req
    );
  } catch (err) {
    console.log(err);
    return {} as CreatePostResponse;
  }

  const retres: CreatePostResponse = {
    id: response?.data?.data.id,
  };

  return retres;
};
export default createNewPost;
