import axios from "axios";
import { GetListsResponse } from "types/getLists";

const getLists = async (): Promise<GetListsResponse> => {
  let response;
  try {
    response = await axios.post(`${process.env.REACT_APP_BE_API}/api/lists`);
  } catch (err) {
    console.log(err);
    return {} as GetListsResponse;
  }

  const resRes = response.data.data;
  return resRes;
};

export default getLists;
