import { Box, Button, Heading, Stack, Text } from "@chakra-ui/react";
import { useState } from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";

import createNewPost from "services/posts/CreateNewPost";
import { CreatePostRequest } from "types/createPostRequest";
import TitleInput from "components/CreatePostPage/FormComponents/TitleInput";
import ExcerptInput from "components/CreatePostPage/FormComponents/ExcerptInput";
import DescriptionInput from "components/CreatePostPage/FormComponents/DescriptionInput";
import HeaderImageUpload from "components/CreatePostPage/FormComponents/HeaderImageUpload";
import ImagesUpload from "components/CreatePostPage/FormComponents/ImagesUpload";
import RecommendedItemsInput from "components/CreatePostPage/FormComponents/RecommendedItemsInput";
import TagsPicker from "components/CreatePostPage/FormComponents/TagsPicker";
import IsHiddenSwitch from "components/CreatePostPage/FormComponents/IsHiddenSwitch";
import ScoresInput from "components/CreatePostPage/FormComponents/ScoresInput";
import RestaurantPicker from "components/CreatePostPage/FormComponents/RestaurantPicker";
import LocationTagsPicker from "components/CreatePostPage/FormComponents/LocationTagsPicker";
import { ROUTES } from "constants/routes";

const defaultDescriptionTemplate = "Food:\n\nService:\n\nConclusion:\n";

export interface FormInputs {
  restaurantId: string;
  name: string;
  excerpt: string;
  header_image: string;
  description: string;
  images: string[];
  recommended_items: string[];
  tags: string[];
  location_tags: string[];
  is_hidden: boolean;
  taste: number;
  service: number;
  location: number;
  price: number;
  cleanliness: number;
}

const defaultFormValues: FormInputs = {
  restaurantId: "",
  name: "",
  excerpt: "",
  header_image: "",
  description: defaultDescriptionTemplate,
  images: [],
  recommended_items: [],
  tags: [],
  location_tags: [],
  is_hidden: false,
  taste: 5,
  service: 5,
  location: 5,
  price: 5,
  cleanliness: 5,
};

const CreatePostPageDemo = () => {
  const [searchParams] = useSearchParams();

  const restaurantID = searchParams.get("restaurant_id");

  const preselected = restaurantID ? "651e3f23a2fa7a290f9140a7" : "";

  const {
    control,
    handleSubmit,
    setValue,
    register,
    formState: { errors, isSubmitting },
  } = useForm<FormInputs>({ defaultValues: defaultFormValues });

  const [submitError, setSubmitError] = useState("");

  const navigate = useNavigate();

  const onSubmit: SubmitHandler<FieldValues> = async (values) => {
    const formattedValues: CreatePostRequest = {
      restaurant_id: values.restaurant_id.value,
      name: values.name,
      excerpt: values.excerpt,
      header_image: values.header_image,
      description: values.description,
      images: values.images.split("\n"),
      recommended_items: values.recommended_items.split("\n"),
      tags: values.tags.map((help: any) => {
        return help.value;
      }),
      location_tags: values.location_tags.map((val: any) => val.value),
      is_hidden: !!values.is_hidden,
      score_breakdown: {
        taste: values.taste,
        service: values.service,
        location: values.location,
        price: values.price,
        cleanliness: values.cleanliness,
      },
    };

    const isSuccess = await createNewPost(formattedValues);

    if (isSuccess?.id) {
      navigate(`${ROUTES.POST}/${isSuccess.id}`);
      return;
    }
    setSubmitError(
      "Error occured when submitting your create form. Make sure you log out and log in again when you want to create a new post."
    );
    return console.log(formattedValues);
  };

  return (
    <Box p={4}>
      <Stack align="center">
        <Box w="95%" maxW="lg">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack>
              {/* Restaurant choice */}
              <Heading>Details</Heading>
              <RestaurantPicker
                control={control}
                preselected={preselected}
                setVal={setValue}
              />
              <TitleInput register={register} errors={errors} />
              <ExcerptInput register={register} errors={errors} />
              <DescriptionInput register={register} errors={errors} />
              <HeaderImageUpload
                errors={errors}
                formValueKey="header_image"
                setValue={setValue}
              />
              <ImagesUpload
                register={register}
                errors={errors}
                setValue={setValue}
              />
              <RecommendedItemsInput register={register} errors={errors} />
              <TagsPicker control={control} />
              <LocationTagsPicker control={control} />
              <IsHiddenSwitch control={control} />
              <ScoresInput control={control} />
            </Stack>
            {submitError.length > 0 && (
              <Text textColor="red.500">{submitError}</Text>
            )}
            <Button
              mt={4}
              colorScheme="teal"
              isLoading={isSubmitting}
              type="submit"
              isDisabled={!errors}
            >
              Submit
            </Button>
          </form>
        </Box>
      </Stack>
    </Box>
  );
};

export default CreatePostPageDemo;
