import { Box, Stack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import SearchBar from "components/SearchBar/SearchBar";
import PostCard from "components/PostCard/PostCard";
import { MiniPost } from "types/post";
import { PageState } from "types/page_state";
import searchPosts from "services/posts/SearchPosts";
import ErrorOccurred from "components/Errors/NotFound";
import Loading from "components/Loading/Loading";

const SearchPage = () => {
  const [listOfPlaces, setListOfPlaces] = useState<MiniPost[]>(
    [] as MiniPost[]
  );
  const [state, setState] = useState<PageState>(PageState.InitialState);
  const [searchParams, setSearchParams] = useSearchParams();

  const queryParam = searchParams.get("query") ?? "";
  const sortByParam = searchParams.get("sort_by") ?? "";

  useEffect(() => {
    const helper = async () => {
      setState(PageState.LoadingState);
      const posts = await searchPosts(queryParam, sortByParam);

      if (Object.keys(posts).length <= 0) {
        setState(PageState.ErrorState);
        return;
      }

      if (!posts?.result?.length) {
        setState(PageState.ErrorState);
        return;
      }

      setListOfPlaces(posts.result);
      setState(PageState.SuccessState);
    };

    helper();
  }, [queryParam, sortByParam]);

  const onSearch = async (searchValue: string, sortBy: string) => {
    setState(PageState.LoadingState);
    const params = { query: searchValue, sort_by: sortBy };

    setSearchParams(params);
    const posts = await searchPosts(searchValue, sortBy);

    if (Object.keys(posts).length <= 0) {
      console.log("errorororor");
      setState(PageState.ErrorState);
      return;
    }

    if (!posts?.result?.length) {
      setState(PageState.ErrorState);
      return;
    }
    setListOfPlaces(posts.result);
    setState(PageState.SuccessState);
  };

  if (state === PageState.LoadingState) {
    return <Loading />;
  }

  return (
    <Box p={4}>
      <Stack align={"center"}>
        <Box mb={3} w={"95%"} maxW={"sm"}>
          <Stack>
            <SearchBar
              onSearch={onSearch}
              fill={queryParam}
              sortBy={sortByParam}
            />
            {state === PageState.ErrorState ? (
              <ErrorOccurred text="No results found for your search term. Please try to search something else" />
            ) : (
              listOfPlaces.map((val) => (
                <PostCard
                  key={val.id}
                  id={val.id}
                  name={val.name}
                  description={val.excerpt}
                  created_at={val.created_at}
                  score={val.score}
                />
              ))
            )}
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};

export default SearchPage;
