import { Box, useColorModeValue } from "@chakra-ui/react";

interface NavLinkProps {
  children: React.ReactNode;
  path: string;
}

const NavLink = ({ children, path }: NavLinkProps) => (
  <Box
    as="a"
    px={2}
    py={1}
    rounded="md"
    _hover={{
      textDecoration: "none",
      bg: useColorModeValue("gray.200", "gray.700"),
    }}
    href={path}
  >
    {children}
  </Box>
);

export default NavLink;
