import { Box, Center, Flex, Select, Stack, Text } from "@chakra-ui/react";
import { IoIosFunnel } from "react-icons/io";
import { AnimatePresence } from "framer-motion";

import RestaurantListCard from "./RestaurantCard";

const RestaurantList = ({
  list,
  onMoveHandler,
  onRemoveHandler,
}: {
  list: any[];
  onMoveHandler: (restaurantId: string) => () => void;
  onRemoveHandler: (restaurantId: string) => () => void;
}) => {
  return (
    <Box minH={"100vh"}>
      <Flex w={"100%"} justify={"right"}>
        <Select icon={<IoIosFunnel />} w={"50%"}>
          <option value="open">Open Now</option>
          <option value="bestscore">Best score</option>
        </Select>
      </Flex>
      {list.length ? (
        <Stack mt={4}>
          <AnimatePresence initial={false}>
            {list.map((val: any) => {
              return (
                <RestaurantListCard
                  key={val.id}
                  id={val.id}
                  name={val.name}
                  postScore={val.average_score}
                  isOpen={val.is_open}
                  onMoveHandler={onMoveHandler(val.id)}
                  onRemoveHandler={onRemoveHandler(val.id)}
                />
              );
            })}
          </AnimatePresence>
        </Stack>
      ) : (
        <>
          <Center h={"75vh"}>
            <Text>Add something now!</Text>
          </Center>
        </>
      )}
    </Box>
  );
};

export default RestaurantList;
