import { Control, Controller } from "react-hook-form";
import {
  FormControl,
  FormLabel,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from "@chakra-ui/react";

const ScoresInput = ({ control }: { control: Control<any> }) => {
  const scoringInputs = [
    { name: "taste", label: "Taste" },
    { name: "service", label: "Service" },
    { name: "price", label: "Price" },
    { name: "location", label: "Location" },
    { name: "cleanliness", label: "Cleanliness" },
  ];
  return (
    <>
      {scoringInputs.map((score) => {
        return (
          <Controller
            key={score.name}
            control={control}
            name={score.name as any}
            render={({ field: { onChange, value, name } }) => {
              return (
                <FormControl>
                  <FormLabel>{score.label}</FormLabel>
                  <NumberInput
                    min={0}
                    max={10}
                    defaultValue={5}
                    onChange={(s, n) => onChange(n)}
                    value={value as number}
                    name={name}
                  >
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </FormControl>
              );
            }}
          />
        );
      })}
    </>
  );
};

export default ScoresInput;
