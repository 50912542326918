import { Box, Heading, Stack, Text } from "@chakra-ui/react";

const RecommendationView = ({
  recommendedItem,
}: {
  recommendedItem: string[];
}) => {
  if (recommendedItem.length <= 0) {
    return <></>;
  }
  return (
    <Box mt={5}>
      <Heading>Recommended Items</Heading>
      <Stack mt={5}>
        {recommendedItem.map((val, i) => {
          return (
            <Text key={`${val.slice(0, 10)}${i}`} align={"justify"}>
              {`${i + 1}. ${val}`}
            </Text>
          );
        })}
      </Stack>
    </Box>
  );
};

export default RecommendationView;
