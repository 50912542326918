import axios from "axios";
import { AuthInfo } from "types/auth";

const loginService = async (
  identifier: string,
  password: string
): Promise<AuthInfo> => {
  const res = await axios.post(
    `${process.env.REACT_APP_BE_API}/api/auth/login`,
    {
      identifier: identifier,
      password: password,
    }
  );

  return res.data?.data;
};

export default loginService;
