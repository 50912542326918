const notifyNewFeature: (email: string) => Promise<boolean> = async (email: string): Promise<boolean> => {
    let isSuccess = true

    let response;
    try {
        response = await fetch(`${process.env.REACT_APP_BE_API}/api/notify`, {method:"POST", body:JSON.stringify({email:email})})
    } catch (err) {
        console.log(err)
        return false
    }
    
    if (!response.ok) {
        return false
    }

    const res = await response.json()
    console.log(res)
    
    return isSuccess
}

export default notifyNewFeature
