import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Textarea,
} from "@chakra-ui/react";
import { FieldErrors, UseFormRegister } from "react-hook-form";

const RecommendedItemsInput = ({
  errors,
  register,
}: {
  errors: FieldErrors<any>;
  register: UseFormRegister<any>;
}) => {
  return (
    <FormControl isInvalid={!!errors?.recommended_items}>
      <FormLabel>Recommended Items</FormLabel>
      <Textarea
        placeholder="recommended items"
        rows={5}
        {...register("recommended_items", {
          required: "This is required",
          minLength: {
            value: 10,
            message: "Minimum length should be 10",
          },
        })}
      />
      <FormHelperText>Food items you recommend</FormHelperText>
      <FormErrorMessage>
        {errors.recommended_items?.message?.toString()}
      </FormErrorMessage>
    </FormControl>
  );
};

export default RecommendedItemsInput;
