import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import useAuth from "../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";

const defaultFormValues = {
  identifier: "",
  password: "",
};

const LoginView = ({
  referrer,
  message,
}: {
  referrer: string;
  message?: string;
}) => {
  const { login } = useAuth();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const handleClick = () => setShow(!show);

  const {
    handleSubmit,
    register,
    reset,
    // eslint-disable-next-line
    formState: { errors, isSubmitting },
  } = useForm({ defaultValues: defaultFormValues });

  const onSubmit: SubmitHandler<FieldValues> = async (values) => {
    const isSuccess = await login(values.identifier, values.password);
    if (!isSuccess) {
      reset();
      setErrorMessage("Invalid Credentials. Please try again");
      return;
    }
    navigate(referrer);
    setErrorMessage("");
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={4}>
        <Box>
          <Heading size={"lg"}>Login</Heading>
          {message ? (
            <Text textColor={"red.500"}>{message}</Text>
          ) : (
            <Text>Welcome Back</Text>
          )}
        </Box>
        <FormControl isInvalid={!!errors.identifier}>
          <Input
            type="text"
            id="identifier"
            placeholder="Username or Email"
            {...register("identifier", {
              required: "This is required",
              minLength: {
                value: 4,
                message: "Minimum length should be 4",
              },
            })}
          />
          <FormErrorMessage>{errors.identifier?.message}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.password}>
          <InputGroup size="md">
            <Input
              type={show ? "text" : "password"}
              id="password"
              placeholder="Password"
              {...register("password", {
                required: "This is required",
                minLength: {
                  value: 6,
                  message: "Minimum length should be 6",
                },
              })}
            />
            <InputRightElement width="4.5rem">
              <Button h="1.75rem" size="sm" onClick={handleClick}>
                {show ? "Hide" : "Show"}
              </Button>
            </InputRightElement>
          </InputGroup>
          <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
        </FormControl>
        {errorMessage.length !== 0 && (
          <Text color={"red.500"}>{errorMessage}</Text>
        )}
        <Button
          mt={4}
          colorScheme="teal"
          isLoading={isSubmitting}
          type="submit"
          isDisabled={!errors}
        >
          Submit
        </Button>
      </Stack>
    </form>
  );
};

export default LoginView;
