import { CloseIcon } from "@chakra-ui/icons";
import {
  AspectRatio,
  Box,
  Container,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text,
  Image,
  HStack,
  IconButton,
} from "@chakra-ui/react";
import { motion, useAnimation } from "framer-motion";
import { useEffect } from "react";
import { FieldErrors, UseFormRegister, UseFormSetValue } from "react-hook-form";

import useImageUpload from "hooks/useImageUpload";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import { FormInputs } from "pages/CreatePostPageDemo";

const ImagesUpload = ({
  errors,
  register,
  setValue,
}: {
  errors: FieldErrors<FormInputs>;
  register: UseFormRegister<FormInputs>;
  setValue: UseFormSetValue<FormInputs>;
}) => {
  const controls = useAnimation();
  const startAnimation = () => controls.start("hover");
  const stopAnimation = () => controls.stop();
  const { images, uploadImages, isUploading, removeImage } = useImageUpload();

  useEffect(() => {
    setValue("images", images);
  }, [setValue, images]);

  return (
    <FormControl isInvalid={errors?.images && true}>
      <FormLabel>Images</FormLabel>

      <Container p={0}>
        <AspectRatio ratio={3}>
          <Box
            {...register("images")}
            borderColor={errors.images?.message ? "red.500" : "gray.300"}
            borderStyle="dashed"
            borderWidth="2px"
            rounded="md"
            shadow="sm"
            role="group"
            transition="all 150ms ease-in-out"
            _hover={isUploading ? {} : { shadow: "md" }}
            as={motion.div}
            initial="rest"
            animate="rest"
            whileHover="hover"
          >
            <Box position="relative" height="100%" width="100%">
              <Box
                position="absolute"
                top="0"
                left="0"
                height="100%"
                width="100%"
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                {isUploading ? (
                  <HStack alignSelf="center">
                    <Text>Uploading...</Text>
                    <LoadingSpinner size="md" />
                  </HStack>
                ) : (
                  <Stack p="8" textAlign="center" spacing="1">
                    <Heading fontSize="lg" color="gray.700" fontWeight="bold">
                      Drop images here
                    </Heading>
                    <Text fontWeight="light">or click to upload</Text>
                  </Stack>
                )}
              </Box>
              <Input
                value={""} // to trigger onchange even with same image
                disabled={isUploading}
                type="file"
                style={{ opacity: 0 }}
                multiple
                height="100%"
                width="100%"
                position="absolute"
                top="0"
                left="0"
                opacity="0"
                aria-hidden="true"
                accept="image/*"
                onDragEnter={startAnimation}
                onDragLeave={stopAnimation}
                onChange={(event) => uploadImages(event.target.files)}
              />
            </Box>
          </Box>
        </AspectRatio>

        {images.length > 0 && (
          <HStack w="full" my={4} spacing={0} overflowY="auto">
            {images.map((image) => (
              <Box
                position="relative"
                margin={4}
                aspectRatio={1}
                w="150px"
                h="150px"
                key={image}
              >
                <IconButton
                  isRound
                  aria-label="Remove image"
                  icon={<CloseIcon />}
                  position="absolute"
                  right={-4}
                  top={-4}
                  fontSize={8}
                  size="xs"
                  onClick={() => removeImage(image)}
                />
                <Image w="full" h="full" src={image} objectFit="cover" />
              </Box>
            ))}
          </HStack>
        )}
      </Container>

      <FormErrorMessage>{errors.images?.message?.toString()}</FormErrorMessage>
    </FormControl>
  );
};

export default ImagesUpload;
