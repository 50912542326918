import {
  Box,
  Card,
  Center,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";

import LoginView from "components/AuthPage/LoginView/LoginView";
import RegisterView from "components/AuthPage/LoginView/RegisterView";

const AuthPage = () => {
  const { state } = useLocation();

  const referrer = state?.path || "/";
  const message = state?.message;
  console.log(message);

  return (
    <Box p={4}>
      <Center>
        <Card w={"90%"} maxW={"lg"} py={4}>
          <Tabs>
            <TabList>
              <Tab>Login</Tab>
              <Tab>Register</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <LoginView referrer={referrer} message={message} />
              </TabPanel>
              <TabPanel>
                <RegisterView referrer={referrer} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Card>
      </Center>
    </Box>
  );
};

export default AuthPage;
