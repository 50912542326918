import {
  Box,
  Flex,
  Heading,
  LinkBox,
  LinkOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";
import { format } from "date-fns";

import { chooseScoreColor } from "helpers/ScoreColor";

const PostCard = ({
  id,
  name,
  description,
  created_at,
  score,
}: {
  id: string;
  name: string;
  description: string;
  created_at: number;
  score: number;
}) => {
  let createdDate = "";
  if (created_at) {
    createdDate = format(created_at * 1000, "dd/MM/yyyy");
  }

  return (
    <>
      <LinkBox
        as="article"
        w="100%"
        // maxW="sm"
        p="5"
        borderWidth="1px"
        rounded="md"
      >
        <Flex justifyContent={"space-between"}>
          <Box maxW={"75%"}>
            <Stack spacing={0}>
              <Box as="time" dateTime="2021-01-15 15:30:00 +0000 UTC">
                {createdDate}
              </Box>
              <Heading size="md" my="2">
                <LinkOverlay href={`/post/${id}`}>{name}</LinkOverlay>
              </Heading>
            </Stack>
          </Box>
          <Stack spacing={0}>
            <Text>f-score</Text>
            <Text color={chooseScoreColor(score)} as="b">
              {score}/10
            </Text>
          </Stack>
        </Flex>
        <Text noOfLines={2}>{description}</Text>
      </LinkBox>
    </>
  );
};

export default PostCard;
