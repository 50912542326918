import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";

const defaultFormValues = {
  username: "",
  email: "",
  password: "",
  referral_key: "",
};
const RegisterView = ({ referrer }: { referrer: string }) => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const handleClick = () => setShow(!show);

  const { register: registerService } = useAuth();

  const {
    handleSubmit,
    register,
    // eslint-disable-next-line
    formState: { errors, isSubmitting },
    reset,
  } = useForm({ defaultValues: defaultFormValues });

  const onSubmit: SubmitHandler<FieldValues> = async (values) => {
    const isSuccess = await registerService(
      values.username,
      values.password,
      values.email,
      values.referral_key
    );
    if (!isSuccess) {
      reset();
      setErrorMessage("Something went wrong when registering");
      return;
    }
    navigate(referrer);
    setErrorMessage("");
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={4}>
        <Box>
          <Heading size={"lg"}>Register</Heading>
          <Text>
            Currently we are by invite only. Thank you for the support!!
          </Text>
        </Box>
        <FormControl isInvalid={!!errors.username}>
          <Input
            type="text"
            id="username"
            placeholder="Username"
            {...register("username", {
              required: "This is required",
              minLength: {
                value: 4,
                message: "Minimum length should be 4",
              },
            })}
          />
          <FormErrorMessage>{errors.username?.message}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.email}>
          <Input
            type="email"
            id="email"
            placeholder="Email"
            {...register("email", {
              required: "This is required",
              minLength: {
                value: 4,
                message: "Minimum length should be 4",
              },
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "invalid email address",
              },
            })}
          />
          <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.password}>
          <InputGroup size="md">
            <Input
              type={show ? "text" : "password"}
              id="password"
              placeholder="Password"
              {...register("password", {
                required: "This is required",
                minLength: {
                  value: 6,
                  message: "Minimum length should be 6",
                },
              })}
            />
            <InputRightElement width="4.5rem">
              <Button h="1.75rem" size="sm" onClick={handleClick}>
                {show ? "Hide" : "Show"}
              </Button>
            </InputRightElement>
          </InputGroup>
          <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.referral_key}>
          <Input
            type="text"
            id="referral_key"
            placeholder="Referral Code"
            {...register("referral_key", {
              required: "This is required",
              minLength: {
                value: 4,
                message: "Minimum length should be 4",
              },
            })}
          />
          <FormErrorMessage>{errors.referral_key?.message}</FormErrorMessage>
        </FormControl>
        {errorMessage.length !== 0 && (
          <Text color={"red.500"}>{errorMessage}</Text>
        )}
        <Button
          mt={4}
          colorScheme="teal"
          isLoading={isSubmitting}
          type="submit"
          isDisabled={!errors}
        >
          Submit
        </Button>
      </Stack>
    </form>
  );
};

export default RegisterView;
