import React, { ReactNode, createContext, useState } from "react";
import loginService from "../services/auth/login";
import jwtDecode from "jwt-decode";
import registerService from "../services/auth/register";
import { useToast } from "@chakra-ui/react";

const TokenKey = "token";
const UsernameKey = "username";
const DisplayImageKey = "display_image";

interface jwtDecoded {
  exp: number;
}

const authContext = createContext({
  authed: false,
  login: async (identifier: string, password: string): Promise<boolean> => {
    console.log("login function not set");
    return false;
  },
  logout: () => {
    console.log("logout function not set");
  },
  register: async (
    username: string,
    password: string,
    email: string,
    referral_key: string
  ): Promise<boolean> => {
    console.log("register function not set");
    return false;
  },
  isTokenExpire: (): boolean => {
    console.log("isTokenExpire function not set");
    return true;
  },
});

const setUserInfo = (username: string, displayImage: string) => {
  localStorage.setItem(UsernameKey, username);
  localStorage.setItem(DisplayImageKey, displayImage);
};

const clearUserInfo = () => {
  localStorage.removeItem(UsernameKey);
  localStorage.removeItem(DisplayImageKey);
};

const useAuth = () => {
  const [authed, setAuthed] = useState(!!localStorage.getItem(TokenKey));
  const toast = useToast();

  const login = async (
    identifier: string,
    password: string
  ): Promise<boolean> => {
    try {
      const loginData = await loginService(identifier, password);
      console.log(loginData);

      localStorage.setItem(TokenKey, loginData.token);

      if (loginData?.user_info) {
        setUserInfo(
          loginData.user_info.username,
          loginData.user_info.header_image
        );
      }
      setAuthed(true);
      return Object.keys(loginData).length > 0;
    } catch (err) {
      console.log(err);
      return false;
    }
  };
  const logout = () => {
    toast({
      title: "You have been logged out.",
      description: "Can't wait for you to join us again.",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    localStorage.removeItem(TokenKey);
    clearUserInfo();
    setAuthed(false);
  };
  const register = async (
    username: string,
    password: string,
    email: string,
    referral_key: string
  ): Promise<boolean> => {
    try {
      const registerData = await registerService(
        username,
        password,
        email,
        referral_key
      );
      localStorage.setItem(TokenKey, registerData.token);
      if (registerData?.user_info) {
        setUserInfo(
          registerData.user_info.username,
          registerData.user_info.header_image
        );
      }
      setAuthed(true);
      return Object.keys(registerData).length > 0;
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const isTokenExpire = (): boolean => {
    const token = localStorage.getItem(TokenKey);
    if (!token) {
      clearUserInfo();
      setAuthed(false);
      return true;
    }

    const decoded = jwtDecode<jwtDecoded>(token);

    const expireTime = decoded.exp;

    const expirationTime = expireTime * 1000;
    if (Date.now() >= expirationTime) {
      localStorage.removeItem(TokenKey);
      clearUserInfo();
      setAuthed(false);
      return true;
    }

    return false;
  };

  return {
    authed,
    login,
    logout,
    register,
    isTokenExpire,
  };
};

export function AuthProvider({ children }: { children: ReactNode }) {
  const auth = useAuth();

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export default function AuthConsumer() {
  return React.useContext(authContext);
}
