import axios from "axios";
import { AddToListResponse } from "types/addToListResponse";
import { AddToListRequest } from "types/addToListRequest";

const addToList = async (req: AddToListRequest): Promise<AddToListResponse> => {
  let response;

  try {
    response = await axios.post(
      `${process.env.REACT_APP_BE_API}/api/list/add`,
      req
    );
  } catch (err) {
    console.log(err);
    return {} as AddToListResponse;
  }

  const resRes = response.data.data;
  return resRes;
};

export default addToList;
