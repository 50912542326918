import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Heading,
  Image,
  Stack,
  Tag,
  Text,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import { Restaurant } from "types/restaurant";
import { generateRestaurantWithIdRoute } from "helpers/generateRoutes";

const RestaurantCard = ({ restaurant }: { restaurant: Restaurant }) => {
  const navigate = useNavigate();
  const onClickRestaurant = (restaurantId: string) => {
    return () => navigate(generateRestaurantWithIdRoute(restaurantId));
  };

  return (
    <Box key={restaurant.id} h={"400px"}>
      <Card w={{ base: "230px", md: "225px" }} h={"100%"}>
        <Image
          src={restaurant.header_image}
          alt={restaurant.name}
          borderTopRadius={"lg"}
          h={"40%"}
          w={"100%"}
          objectFit={"cover"}
        />
        <CardHeader pt={"10px"} pb={"0px"}>
          <Heading size="md" noOfLines={2}>
            {restaurant.name}
          </Heading>
        </CardHeader>
        <CardBody py={"5px"} overflowY={"auto"}>
          <Stack>
            <Box>
              <Text>Address:</Text>
              <Text fontSize={"md"} noOfLines={2}>
                {restaurant.location_details.address}
              </Text>
            </Box>
            <Box>
              {restaurant?.is_open ? (
                <Tag colorScheme="teal">Open</Tag>
              ) : (
                <Tag colorScheme="red">Closed</Tag>
              )}
            </Box>
            {/* <OverallFScore
              postScore={restaurant.average_score}
              hideInfoButton={true}
            /> */}
          </Stack>
        </CardBody>
        <CardFooter py={"10px"}>
          <Button onClick={onClickRestaurant(restaurant.id)} w={"100%"}>
            View more
          </Button>
        </CardFooter>
      </Card>
    </Box>
  );
};

export default RestaurantCard;
