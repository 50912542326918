import { Box, Link, Text } from "@chakra-ui/react";

const AuthorView = ({ username, link }: { username: string; link: string }) => {
  if (username === "") {
    return <></>;
  }
  return (
    <Box>
      <Text>
        By:{" "}
        <Link color="teal.500" href={link}>
          {username}
        </Link>
      </Text>
    </Box>
  );
};

export default AuthorView;
