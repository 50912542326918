import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { FieldErrors, UseFormRegister } from "react-hook-form";

const TitleInput = ({
  errors,
  register,
}: {
  errors: FieldErrors<any>;
  register: UseFormRegister<any>;
}) => {
  return (
    <FormControl isInvalid={errors?.name && true}>
      <FormLabel>Title</FormLabel>
      <Input
        type="text"
        id="name"
        {...register("name", {
          required: "This is required",
          minLength: {
            value: 4,
            message: "Minimum length should be 4",
          },
        })}
      />
      <FormErrorMessage>
        <>{errors.name?.message}</>
      </FormErrorMessage>
    </FormControl>
  );
};

export default TitleInput;
