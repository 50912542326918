import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Card,
  Center,
  Flex,
  HStack,
  Heading,
  Icon,
  IconButton,
  Image,
  Link,
  Stack,
  Tag,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AddIcon, TimeIcon } from "@chakra-ui/icons";
import { LatLngExpression } from "leaflet";
import { IoMdShare } from "react-icons/io";
import { BiBookmark, BiSolidBookmark } from "react-icons/bi";

import useAuth from "hooks/useAuth";
import PostCard from "components/PostCard/PostCard";
import { MiniPost } from "types/post";
import PostMap from "components/PostMap/PostMap";
import { PageState } from "types/page_state";
import getRestaurant from "services/restaurant/getRestaurant";
import { Restaurant } from "types/restaurant";
import Loading from "components/Loading/Loading";
import ErrorOccurred from "components/Errors/NotFound";
import OverallFScore from "components/PostPage/F_score/OverallFScore";
import addToList from "services/list/addToList";
import { AddToListRequest } from "types/addToListRequest";
import { ROUTES } from "constants/routes";

const RestaurantPage = () => {
  const addressBarUrl = process.env.REACT_APP_FE_URL
    ? process.env.REACT_APP_FE_URL
    : "https://food.pinsern.com";

  let { id } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const { isTokenExpire } = useAuth();
  const location = useLocation();
  const [state, setState] = useState<PageState>(PageState.InitialState);

  const [restaurantInfo, setRestaurantInfo] = useState<
    Restaurant | undefined
  >();
  const [recent, setRecent] = useState<MiniPost[] | undefined>();
  const [saved, setSaved] = useState<boolean>(false);
  const [saveButtonLoadingState, setSaveButtonLoadingState] = useState(false);

  useEffect(() => {
    const helper = async () => {
      setState(PageState.LoadingState);
      const restaurant = await getRestaurant(id);

      if (!restaurant) {
        setState(PageState.ErrorState);
        return;
      }

      if (Object.keys(restaurant).length === 0) {
        setState(PageState.ErrorState);
        return;
      }

      setRestaurantInfo(restaurant.info);
      setRecent(restaurant.recent_posts);
      setSaved(restaurant.info.saved);

      setState(PageState.SuccessState);
    };

    helper();
  }, [id]);

  if (state === PageState.LoadingState || state === PageState.InitialState) {
    return <Loading />;
  }

  if (state === PageState.ErrorState) {
    return (
      <>
        <ErrorOccurred text="Restaurant not found" />
      </>
    );
  }

  const headerImage = restaurantInfo?.header_image
    ? restaurantInfo.header_image
    : "https://res.cloudinary.com/dskpstidt/image/upload/v1695552592/mbsstock2_jggkoc.jpg";

  const restaurantName = restaurantInfo?.name
    ? restaurantInfo.name
    : "There should be a name here";

  const addressInfo = restaurantInfo?.location_details?.address
    ? restaurantInfo.location_details.address
    : "no address info";
  const averageScore = restaurantInfo?.average_score
    ? restaurantInfo.average_score
    : 0;

  // max of 5 posts with a show more at the bottom if there is more
  // link to see other branches
  // Group View

  const recentPosts: MiniPost[] = recent ? recent : [];

  const long = restaurantInfo?.location_details?.long
    ? restaurantInfo.location_details.long
    : 103;

  const lat = restaurantInfo?.location_details?.lat
    ? restaurantInfo.location_details.lat
    : 1;
  const position: LatLngExpression = [lat, long];
  const googleMapsLink = restaurantInfo?.location_details?.google_link
    ? restaurantInfo.location_details.google_link
    : "https://maps.google.com";

  const onClickCreate = () => {
    if (!id) {
      return;
    }
    navigate(`${ROUTES.CREATE_POST}?restaurant_id=${id}`);
  };

  const onClickSaved = async () => {
    // check if user is logged in
    // if not logged in, we need to redirect to login page
    // this check is done by axios as a fallback

    if (isTokenExpire()) {
      navigate(ROUTES.AUTH, {
        state: { path: location.pathname },
      });
      return;
    }

    // is saved, redirect to list page
    if (saved) {
      navigate(ROUTES.LIST);
      return;
    }
    // if not saved, show toast and stay on page
    if (!id) {
      console.log("no id");
      return;
    }

    const addRequest: AddToListRequest = {
      restaurant_id: id,
    };

    setSaveButtonLoadingState(true);

    const res = await addToList(addRequest);

    // check success or fail

    if (!res || Object.keys(res).length === 0) {
      toast({
        status: "error",
        duration: 5000,
        isClosable: true,
        render: () => (
          <Box color="white" p={3} bg="teal.500" borderRadius={"5px"}>
            <Text as={"b"}>Error Occurred</Text>
            <Text>Please try again later</Text>
          </Box>
        ),
      });
      return;
    }

    const saveListName = res.list_name;

    setSaved(true);
    setSaveButtonLoadingState(false);

    toast({
      status: "success",
      duration: 5000,
      isClosable: true,
      render: () => (
        <Box color="white" p={3} bg="teal.500" borderRadius={"5px"}>
          <Text as={"b"}>{`Saved to ${saveListName} list`}</Text>
          <Text>
            You can see your list{" "}
            <Link href="/list" style={{ textDecoration: "underline" }}>
              here
            </Link>
          </Text>
        </Box>
      ),
    });
  };

  return (
    <Box p={4}>
      <Stack align={"center"}>
        <Box w={"95%"} maxW={"lg"}>
          <Stack align={"center"}>
            <Box w={"100%"} mb={"-70px"}>
              <Image
                src={headerImage}
                h={"200px"}
                w={"100%"}
                fit={"cover"}
                borderRadius={3}
              />
              <Box
                position={"relative"}
                transform={"auto"}
                translateY={"-80px"}
                w={"90%"}
                ml={"auto"}
                mr={"auto"}
              >
                <Card p={4}>
                  <Stack>
                    <Heading size="lg" my="2">
                      {restaurantName}
                    </Heading>
                    <HStack justify={"space-between"}>
                      {restaurantInfo?.is_open ? (
                        <Tag colorScheme="teal">Open</Tag>
                      ) : (
                        <Tag colorScheme="red">Closed</Tag>
                      )}
                      <HStack>
                        <IconButton
                          size={"md"}
                          variant={"outline"}
                          colorScheme="teal"
                          aria-label="share"
                          icon={<IoMdShare />}
                          onClick={() => {
                            const clipboard = navigator.clipboard;
                            if (clipboard) {
                              navigator.clipboard.writeText(
                                `${addressBarUrl}/restaurant/${id}`
                              );
                              toast({
                                status: "success",
                                duration: 2000,
                                isClosable: true,
                                title: "Copied to clipboard!",
                                description:
                                  "You can now paste it and share with friends",
                              });
                              return;
                            }
                            toast({
                              status: "error",
                              duration: 2000,
                              isClosable: true,
                              title: "Error copying to clipboard",
                              description:
                                "Copy the link in the address bar and send it to your friends",
                            });
                          }}
                        />
                        <IconButton
                          size={"md"}
                          variant={saved ? "solid" : "outline"}
                          colorScheme="teal"
                          aria-label="saved"
                          icon={saved ? <BiSolidBookmark /> : <BiBookmark />}
                          onClick={onClickSaved}
                          isLoading={saveButtonLoadingState}
                        />
                      </HStack>
                    </HStack>
                    <Text as={"b"}>Address:</Text>
                    <Text>{addressInfo}</Text>
                    {averageScore ? (
                      <OverallFScore
                        postScore={averageScore}
                        hideInfoButton={false}
                      />
                    ) : (
                      <></>
                    )}
                  </Stack>
                </Card>
              </Box>
            </Box>
            <Box w={"100%"} alignContent={"left"} pl={4}>
              <Flex flexDirection={"row"} justifyContent={"space-between"}>
                <Heading mb={4}>Posts</Heading>
                {recentPosts.length ? (
                  <Button
                    size={"md"}
                    rightIcon={<AddIcon />}
                    onClick={onClickCreate}
                  >
                    Create
                  </Button>
                ) : (
                  <></>
                )}
              </Flex>
              <Stack>
                {recentPosts.length ? (
                  recentPosts.map((val) => {
                    return (
                      <PostCard
                        key={val.id}
                        id={val.id}
                        name={val.name}
                        description={val.excerpt}
                        created_at={val.created_at}
                        score={val.score}
                      />
                    );
                  })
                ) : (
                  <Center h={"xs"}>
                    <Stack align={"center"} gap={3}>
                      <Icon as={TimeIcon} boxSize={"7"} />
                      <Text>No Recent Posts...</Text>
                      <Button rightIcon={<AddIcon />} onClick={onClickCreate}>
                        Create
                      </Button>
                    </Stack>
                  </Center>
                )}
              </Stack>
            </Box>
            <Box w={"100%"} alignContent={"left"} pl={4}>
              <Heading mb={4}>Location</Heading>
              <Box h={"40vh"}>
                <PostMap
                  markerPosition={position}
                  addressText={addressInfo}
                  googleMapsLink={googleMapsLink}
                  restaurantName={restaurantName}
                />
              </Box>
            </Box>
            <Box w={"100%"} alignContent={"left"} pl={4}>
              <Heading>Operating Hours</Heading>
              <Box mt={"4"}>
                <Stack>
                  {restaurantInfo?.operating_hours.daily_hours ? (
                    restaurantInfo.operating_hours.daily_hours
                      .split("\n")
                      .map((val) => {
                        return <Text key={val}>{val}</Text>;
                      })
                  ) : (
                    <></>
                  )}
                  {restaurantInfo?.operating_hours.special_details?.length ? (
                    <Alert status="error" borderRadius={5}>
                      <AlertIcon />
                      <Text>
                        {restaurantInfo.operating_hours.special_details}
                      </Text>
                    </Alert>
                  ) : (
                    <></>
                  )}
                </Stack>
              </Box>
            </Box>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};

export default RestaurantPage;
