import { MiniPost } from "types/post";

const RankPostApi = `${process.env.REACT_APP_BE_API}/api/post/ranker`;

interface RankerPostResponse {
  result: MiniPost[];
}
const getRankedPosts: (
  locations: string[],
  tags: string[],
  percentSimilar: number
) => Promise<RankerPostResponse> = async (
  locations: string[],
  tags: string[],
  percentSimilar: number
): Promise<RankerPostResponse> => {
  // const getRankedPosts: (by: string, tags: string[], percentSimilar: number) => Promise<RankerPostResponse> = async (by: string, tags: string[], percentSimilar: number): Promise<RankerPostResponse> => {

  let resPosts: RankerPostResponse = {} as RankerPostResponse;
  if (percentSimilar > 100) {
    percentSimilar = 100;
  }

  if (percentSimilar < 0) {
    percentSimilar = 0;
  }

  try {
    const response = await fetch(RankPostApi, {
      method: "POST",
      body: JSON.stringify({
        locations: locations,
        tags: tags,
        similar: percentSimilar,
      }),
      credentials: "include",
    });
    const resJson = await response.json();
    resPosts = resJson.data;
  } catch (err) {
    console.log(err);
    return {} as RankerPostResponse;
  }
  return resPosts;
};

export default getRankedPosts;
