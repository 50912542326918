import axios from "axios";
import { RemoveFromListRequest } from "types/removeFromListRequest";
import { RemoveFromListResponse } from "types/removeFromListResponse";

const removeFromList = async (
  req: RemoveFromListRequest
): Promise<RemoveFromListResponse> => {
  let response;

  try {
    response = await axios.post(
      `${process.env.REACT_APP_BE_API}/api/list/remove`,
      req
    );
  } catch (err) {
    console.log(err);

    return {} as RemoveFromListResponse;
  }

  const resRes = response.data.data;
  return resRes;
};

export default removeFromList;
