import { Button, Center, Heading, Stack, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import { ROUTES } from "constants/routes";

const NotFound = () => {
  const navigate = useNavigate();
  const onClickHandler = () => navigate(ROUTES.HOME);

  return (
    <Center>
      <Stack align={"center"}>
        <Heading>404</Heading>
        <Text fontSize={"2xl"}>Not Found</Text>
        <Button colorScheme="teal" variant="solid" onClick={onClickHandler}>
          Go to home
        </Button>
      </Stack>
    </Center>
  );
};

export default NotFound;
