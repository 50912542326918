import axios from "axios";

interface SearchRestaurantsResponse {
  restaurants: { name: string; id: string }[];
}

const GetTagsApi = `${process.env.REACT_APP_BE_API}/api/restaurant/search`;

const searchRestaurants: (
  query: string
) => Promise<SearchRestaurantsResponse> = async (
  query: string
): Promise<SearchRestaurantsResponse> => {
  let resTags: SearchRestaurantsResponse = {} as SearchRestaurantsResponse;
  let response;
  try {
    response = await axios.post(GetTagsApi, {
      method: "POST",
      body: JSON.stringify({ query: query }),
    });
    resTags = response.data.data;
  } catch (err) {
    console.log(err);
    return {} as SearchRestaurantsResponse;
  }

  return resTags;
};

export default searchRestaurants;
