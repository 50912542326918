import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { Control, Controller } from "react-hook-form";

const LocationTagsPicker = ({ control }: { control: Control<any> }) => {
  const locationOptions = ["north", "south", "east", "west", "central"].map(
    (val) => {
      return { value: val, label: val };
    }
  );

  return (
    <Controller
      control={control}
      name={"location_tags"}
      rules={{ required: "Please enter at least one tag." }}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, error },
      }) => {
        return (
          <FormControl isInvalid={invalid}>
            <FormLabel>LocationTags</FormLabel>
            <Select
              isMulti
              closeMenuOnSelect={false}
              options={locationOptions as any}
              onChange={onChange}
              isClearable={false}
              size={"md"}
              value={value}
              onBlur={onBlur}
              ref={ref}
            />
            <FormHelperText>
              Tags that describe where the restaurant is located
            </FormHelperText>
            <FormErrorMessage>{error && error.message}</FormErrorMessage>
          </FormControl>
        );
      }}
    />
  );
};

export default LocationTagsPicker;
