import {
  Box,
  LinkBox,
  LinkOverlay,
  Stack,
  Tag,
  Text,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";

const TagView = ({ mergedTags }: { mergedTags: string[] }) => {
  if (mergedTags.length <= 0) {
    return <></>;
  }
  return (
    <Box mt={5} mb={5}>
      <Stack>
        <Text color={"grey"}>Tags:</Text>
        <Wrap>
          {mergedTags.map((val) => {
            return (
              <WrapItem key={val}>
                <LinkBox>
                  <LinkOverlay href={`/search?query=${val}`}>
                    <Tag>{val}</Tag>
                  </LinkOverlay>
                </LinkBox>
              </WrapItem>
            );
          })}
        </Wrap>
      </Stack>
    </Box>
  );
};

export default TagView;
