import { ReactNode } from "react";
import { Navigate, useLocation } from "react-router-dom";

import useAuth from "hooks/useAuth";

const RequireAuth = ({ children }: { children: ReactNode }) => {
  const { authed, isTokenExpire } = useAuth();
  const location = useLocation();

  const pathName = location.pathname;
  const queries = location.search ? location.search : "";

  const fullPath = pathName + queries;

  return authed && !isTokenExpire() ? (
    <>{children}</>
  ) : (
    <Navigate to="/auth" replace state={{ path: fullPath }} />
  );
};

export default RequireAuth;
