import axios from "axios";
import { NavigateFunction, Location } from "react-router-dom";

import { ROUTES } from "constants/routes";

export const setupInterceptors = (
  navigate: NavigateFunction,
  location: Location
) => {
  axios.interceptors.request.use(function (config) {
    const token = localStorage.getItem("token");
    config.headers.Authorization = "Bearer " + token;
    config.withCredentials = true;

    // check if token expired?

    return config;
  });

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        localStorage.removeItem("token");
        navigate(ROUTES.AUTH, {
          replace: true,
          state: {
            path: location.pathname,
          },
        });
      }
      return Promise.reject(error);
    }
  );
};

export default axios;
