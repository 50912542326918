import { AuthorInfo } from "types/author";
import { MiniPost } from "types/post";

interface GetAuthorResponse {
  info: AuthorInfo;
  recent: MiniPost[];
  recommended: MiniPost[];
}

const getAuthor: (
  id: string | undefined
) => Promise<GetAuthorResponse> = async (
  id: string | undefined
): Promise<GetAuthorResponse> => {
  if (!id) {
    return {} as GetAuthorResponse;
  }
  let resAuthor: GetAuthorResponse = {} as GetAuthorResponse;

  try {
    const response = await fetch(
      `${process.env.REACT_APP_BE_API}/api/author/${id}`,
      { method: "POST", credentials: "include" }
    );
    const resJson = await response.json();
    resAuthor = resJson.data;
  } catch (err) {
    console.log(err);
    return {} as GetAuthorResponse;
  }

  return resAuthor;
};

export default getAuthor;
