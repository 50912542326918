import { useRef, useState } from "react";
import { UseFormSetValue } from "react-hook-form";
import { Box, Button, Image, Input } from "@chakra-ui/react";

import uploadImage from "services/uploadImage/UploadImage";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";

/*
Single image upload
1. uploads to cloudinary
2. returns secure link of image that is uploaded
3. insert as form value
*/
const ImageUploadSingle = ({
  formValueKey,
  setValue,
}: {
  formValueKey: string;
  setValue: UseFormSetValue<any>;
}) => {
  const inputRef = useRef<any>(null);
  const [image, setImage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const onImageChange: React.ChangeEventHandler<HTMLInputElement> = async (
    event
  ) => {
    if (event.target.files && event.target.files[0]) {
      setIsLoading(true);
      const imageFile = event.target.files[0];
      setImage(URL.createObjectURL(imageFile));
      const resLink = await uploadImage(imageFile);
      setValue(formValueKey, resLink);
      setIsLoading(false);
    }
  };

  const onRemoveImageHandler: React.MouseEventHandler<HTMLButtonElement> = (
    event
  ) => {
    event.preventDefault();
    if (inputRef.current) {
      inputRef.current.value = null;
    }
    setImage("");
    setValue(formValueKey, "");
  };

  return (
    <Box>
      <Box p={2}>
        <Input
          ref={inputRef}
          type="file"
          id="header_image"
          onChange={onImageChange}
          accept="image/png, image/jpeg, image/webp"
          border={"none"}
        />
      </Box>
      {image ? (
        <Box p={2} borderWidth={2} borderRadius={5}>
          <Box position={"relative"}>
            {isLoading ? (
              <LoadingSpinner />
            ) : (
              <>
                <Image alt="preview image" src={image} width={"100%"} />
                <Button
                  colorScheme="red"
                  size={"sm"}
                  position={"absolute"}
                  right={"0"}
                  top={"0"}
                  borderRadius={"0"}
                  onClick={onRemoveImageHandler}
                >
                  X
                </Button>
              </>
            )}
          </Box>
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default ImageUploadSingle;
