import { MiniPost } from "types/post";

const SearchPostApi = `${process.env.REACT_APP_BE_API}/api/post/search`;

interface SearchPostResponse {
  result: MiniPost[];
}

const searchPosts: (
  query: string,
  sortBy: string
) => Promise<SearchPostResponse> = async (
  query: string,
  sortBy: string
): Promise<SearchPostResponse> => {
  let resPosts: SearchPostResponse = {} as SearchPostResponse;
  try {
    const response = await fetch(SearchPostApi, {
      method: "POST",
      body: JSON.stringify({ query: query, sort_by: sortBy }),
      credentials: "include",
    });
    const resJson = await response.json();
    resPosts = resJson.data;
  } catch (err) {
    console.log(err);
    return {} as SearchPostResponse;
  }
  return resPosts;
};

export default searchPosts;
