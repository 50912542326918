import axios from "axios";
import { GetRestaurantGroupResponse } from "types/getRestaurantGroupResponse";

const getRestaurantGroup = async (
  id: string | undefined
): Promise<GetRestaurantGroupResponse> => {
  if (!id) {
    return {} as GetRestaurantGroupResponse;
  }

  let response;
  try {
    response = await axios.post(
      `${process.env.REACT_APP_BE_API}/api/group/${id}`
    );
  } catch (err) {
    console.log(err);
    return {} as GetRestaurantGroupResponse;
  }

  const resRes: GetRestaurantGroupResponse = response?.data?.data;

  console.log(resRes);

  return resRes;
};

export default getRestaurantGroup;
