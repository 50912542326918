import { Box, Link, Text } from "@chakra-ui/react";

const RestaurantView = ({ name, link }: { name: string; link: string }) => {
  if (name === "") {
    return <></>;
  }
  return (
    <Box>
      <Text>
        Reviewing:{" "}
        <Link color="teal.500" href={link}>
          {name}
        </Link>
      </Text>
    </Box>
  );
};

export default RestaurantView;
