import { HStack, Heading } from "@chakra-ui/react";

import { Ranking } from "types/post";
import RankCard from "./RankCard";

const RankDisplay = ({ postRanking }: { postRanking: Ranking[] }) => {
  if (postRanking.length <= 0) {
    return <></>;
  }

  return (
    <>
      <Heading>Ranking</Heading>
      <HStack p={1} spacing={4} overflowX="auto">
        {postRanking.map((val, i) => {
          return (
            <RankCard
              key={"ranking" + i}
              position={val.position}
              total={val.total}
              type={val.rank_type}
              tags={val.tags}
            />
          );
        })}
      </HStack>
    </>
  );
};

export default RankDisplay;
