import {
  Avatar,
  Box,
  Center,
  Flex,
  Heading,
  Icon,
  Image,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { TimeIcon } from "@chakra-ui/icons";

import PostCard from "components/PostCard/PostCard";
import { MiniPost } from "types/post";
import { PageState } from "types/page_state";
import getAuthor from "services/author/GetAuthor";
import { AuthorInfo } from "types/author";
import Loading from "components/Loading/Loading";
import ErrorOccurred from "components/Errors/NotFound";

const AuthorPage = () => {
  let { id } = useParams();
  const [state, setState] = useState<PageState>(PageState.InitialState);
  const [author, setAuthor] = useState<AuthorInfo | undefined>();
  const [recommended, setRecommended] = useState<MiniPost[] | undefined>();
  const [recent, setRecent] = useState<MiniPost[] | undefined>();

  useEffect(() => {
    const helper = async () => {
      setState(PageState.LoadingState);
      const author = await getAuthor(id);

      if (!author) {
        setState(PageState.ErrorState);
        return;
      }

      if (Object.keys(author).length === 0) {
        setState(PageState.ErrorState);
        return;
      }

      setAuthor(author.info);
      setRecommended(author.recommended);
      setRecent(author.recent);

      setState(PageState.SuccessState);
    };

    helper();
  }, [id]);

  if (state === PageState.LoadingState || state === PageState.InitialState) {
    return <Loading />;
  }

  if (state === PageState.ErrorState) {
    return (
      <>
        <ErrorOccurred text="Author not found" />
      </>
    );
  }

  const username = author?.username;
  const headerImage = author?.header_image ? author.header_image : "";
  const bgImage = author?.background_image
    ? author.background_image
    : "https://res.cloudinary.com/dskpstidt/image/upload/v1695552592/mbsstock2_jggkoc.jpg";
  const bio = author?.bio;
  const likes = author?.likes ? author.likes : [];
  const dislikes = author?.dislikes ? author.dislikes : [];
  const country = author?.country ? author.country : "Singapore";

  return (
    <Box p={4}>
      <Stack align={"center"}>
        <Box w={"95%"} maxW={"lg"}>
          <Stack align={"center"} gap={3}>
            <Box w={"100%"}>
              <Image
                src={bgImage}
                h={"20vh"}
                w={"100%"}
                fit={"cover"}
                borderRadius={3}
              />
              <Box
                position={"absolute"}
                transform={"auto"}
                translateY={"-50%"}
                p={4}
              >
                <Avatar
                  size={{ base: "xl", md: "2xl" }}
                  src={headerImage}
                  shadow="md"
                />
              </Box>
              <Box textAlign={"left"} p={4} mt={"10%"}>
                <Heading size="lg" my="2">
                  {username}
                </Heading>
                <Text>AuthorId: {id}</Text>
                <Text>{bio}</Text>
              </Box>
            </Box>

            <Flex w={"100%"} gap={3} direction={{ md: "row", base: "column" }}>
              <Box
                bg="green.200"
                rounded="md"
                shadow="md"
                p={5}
                w={{ md: "50%" }}
              >
                <Text>Likes</Text>
                <UnorderedList>
                  {likes.map((val) => {
                    return <ListItem key={val}>{val}</ListItem>;
                  })}
                </UnorderedList>
              </Box>
              <Box
                bg="red.200"
                rounded="md"
                shadow="md"
                p={5}
                w={{ md: "50%" }}
              >
                <Text>Dislikes</Text>
                <UnorderedList>
                  {dislikes.map((val) => {
                    return <ListItem key={val}>{val}</ListItem>;
                  })}
                </UnorderedList>
              </Box>
            </Flex>
            <Box textAlign={"center"}>
              <Heading>One Day Recos</Heading>
              <Text fontSize={"sm"} color={"grey"}>
                If you had one day in {country}, these are what I would
                recommend
              </Text>
            </Box>
            {/* maybe split into breakfast lunch dinner view */}
            {recommended?.length ? (
              recommended.map((val) => {
                return (
                  <PostCard
                    key={val.id}
                    id={val.id}
                    name={val.name}
                    description={val.excerpt}
                    created_at={val.created_at}
                    score={val.score}
                  />
                );
              })
            ) : (
              <Center h={"32"}>
                <Stack align={"center"}>
                  <Icon as={TimeIcon} boxSize={"7"} />
                  <Text>No Recent Posts...</Text>
                </Stack>
              </Center>
            )}
            <Box textAlign={"center"}>
              <Heading mb={0} pb={0}>
                Recent Posts
              </Heading>
            </Box>
            {recent?.length ? (
              recent.map((val) => {
                return (
                  <PostCard
                    key={val.id}
                    id={val.id}
                    name={val.name}
                    description={val.excerpt}
                    created_at={val.created_at}
                    score={val.score}
                  />
                );
              })
            ) : (
              <Center h={"32"}>
                <Stack align={"center"}>
                  <Icon as={TimeIcon} boxSize={"7"} />
                  <Text>No Recent Posts...</Text>
                </Stack>
              </Center>
            )}
            <Box textAlign={"center"}>
              <Heading mb={0} pb={0}>
                Get In Touch
              </Heading>
              <Text fontSize={"sm"} color={"grey"}>
                Lets work together!
              </Text>
              <Center h={"32"}>
                <Stack align={"center"}>
                  <Icon as={TimeIcon} boxSize={"7"} />
                  <Text>Coming Soon...</Text>
                </Stack>
              </Center>
            </Box>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};

// show likes (green) and dislikes (red)
// personal recommendations (if you have 1 day in singapore)
// recent posts
// get in touch
//

export default AuthorPage;
