import axios from "axios";
import { CreateRestaurantRequest } from "types/createRestaurantRequest";
import { CreateRestaurantResponse } from "types/createRestaurantRespone";

const createNewRestaurant: (
  req: CreateRestaurantRequest
) => Promise<CreateRestaurantResponse> = async (
  req: CreateRestaurantRequest
): Promise<CreateRestaurantResponse> => {
  let response;

  try {
    response = await axios.post(
      `${process.env.REACT_APP_BE_API}/api/restaurant/create`,
      req
    );
  } catch (err) {
    console.log(err);
    return {} as CreateRestaurantResponse;
  }

  const retres: CreateRestaurantResponse = {
    id: response?.data?.data.id,
  };

  return retres;
};

export default createNewRestaurant;
