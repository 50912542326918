"use client";

import {
  Box,
  Flex,
  HStack,
  Text,
  IconButton,
  useDisclosure,
  useColorModeValue,
  Stack,
  Divider,
  Button,
  LinkBox,
  LinkOverlay,
  Menu,
  MenuButton,
  Avatar,
  MenuList,
  MenuItem,
  MenuDivider,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";

import NavLink from "./NavLink";
import useAuth from "hooks/useAuth";
import { ReactComponent as Logo } from "foodLogo.svg";
import useAvatarInfo from "hooks/useAvatarInfo";
import { ROUTES } from "constants/routes";

interface MenuLink {
  name: string;
  path: string;
}

interface NavBarProps {
  links: MenuLink[];
}

export default function NavBar({ links }: NavBarProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { authed, logout, isTokenExpire } = useAuth();
  const navigate = useNavigate();
  const { avatarDisplayImage, avatarUsername } = useAvatarInfo();

  const loginHandler = () => {
    onClose();
    navigate(ROUTES.AUTH);
  };

  const navigateToListPage = () => navigate(ROUTES.LIST);
  const navigateToCreatePage = () => navigate(ROUTES.CREATE);

  return (
    <Box
      px={4}
      bg={useColorModeValue("gray.100", "gray.900")}
      position="sticky"
      top={0}
      left={0}
      zIndex={1}
    >
      <Flex
        align={"center"}
        h={16}
        gap={4}
        justifyContent={{ base: "space-between", md: "" }}
      >
        <IconButton
          size="md"
          icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
          aria-label="Open Menu"
          display={{ md: "none" }}
          onClick={isOpen ? onClose : onOpen}
        />
        <Box>
          <LinkBox>
            <LinkOverlay href="/">
              <HStack>
                <Box>
                  <Logo style={{ height: 40, width: 40 }} />
                </Box>
                <Text fontSize="3xl" display={{ base: "flex", md: "none" }}>
                  Food
                </Text>
                <Text display={{ base: "flex", md: "none" }}>by Fang</Text>
              </HStack>
            </LinkOverlay>
          </LinkBox>
        </Box>
        <HStack as="nav" spacing={4} display={{ base: "none", md: "flex" }}>
          {links.map((link) => (
            <NavLink key={link.name} path={link.path}>
              {link.name}
            </NavLink>
          ))}
          {(!authed || isTokenExpire()) && (
            <Button colorScheme="teal" variant="link" onClick={loginHandler}>
              Login
            </Button>
          )}
        </HStack>
        {authed && !isTokenExpire() ? (
          <Flex alignItems="center" marginLeft={{ base: "", md: "auto" }}>
            <Menu>
              <MenuButton
                as={Button}
                rounded="full"
                variant="link"
                cursor="pointer"
                minW={0}
              >
                <Avatar
                  size="sm"
                  src={avatarDisplayImage}
                  name={avatarUsername}
                />
              </MenuButton>
              <MenuList>
                <MenuItem onClick={navigateToCreatePage}>Create</MenuItem>
                <MenuItem onClick={navigateToListPage}>Saved</MenuItem>
                <MenuDivider />
                <MenuItem onClick={logout}>Logout</MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        ) : (
          <Box h={10} w={10} display={{ md: "none" }}></Box>
        )}
      </Flex>

      {isOpen && (
        <Box pb={4} display={{ md: "none" }}>
          <Stack as="nav" spacing={4}>
            {links.map((link) => (
              <NavLink key={link.name} path={link.path}>
                {link.name}
              </NavLink>
            ))}
            <Divider />
            {(!authed || isTokenExpire()) && (
              <Button
                colorScheme="teal"
                size={"sm"}
                variant="link"
                onClick={loginHandler}
              >
                Login/Register
              </Button>
            )}
          </Stack>
        </Box>
      )}
    </Box>
  );
}
