import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
import { Radar } from "react-chartjs-2";
import { Box } from "@chakra-ui/react";

import { ScoreBreakdown } from "types/post";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

export function ScoreBreakdownChart({
  breakdown,
}: {
  breakdown: ScoreBreakdown;
}) {
  var options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    scales: {
      r: {
        beginAtZero: true,
        max: 10,
        min: 0,
        pointLabels: {
          font: {
            size: 12,
          },
        },
      },
    },
    responsive: true,
  };

  const data = {
    labels: ["Taste", "Price", "Location", "Service", "Cleanliness"],
    datasets: [
      {
        data: [
          breakdown.taste,
          breakdown.price,
          breakdown.location,
          breakdown.service,
          breakdown.cleanliness,
        ],
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
      },
    ],
  };
  return (
    <>
      <Box>
        <Radar data={data} options={options} />
      </Box>
    </>
  );
}
