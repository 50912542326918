import {
  Box,
  Heading,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

import RestaurantList from "components/ListPage/RestaurantList";
import getLists from "services/list/getLists";
import { ListInfo } from "types/getLists";
import { PageState } from "types/page_state";
import Loading from "components/Loading/Loading";
import moveToList from "services/list/moveToList";
import { MoveToListRequest } from "types/moveToListRequest";
import { RemoveFromListRequest } from "types/removeFromListRequest";
import removeFromList from "services/list/removeFromList";
import ErrorOccurred from "components/Errors/NotFound";

const ListPage = () => {
  const toast = useToast();
  const [pageState, setPageState] = useState<PageState>(PageState.InitialState);
  const [lists, setLists] = useState<ListInfo[]>([]);
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    const helper = async () => {
      setPageState(PageState.LoadingState);
      const listsResponse = await getLists();

      if (!listsResponse || Object.keys(listsResponse).length === 0) {
        setPageState(PageState.ErrorState);
        return;
      }

      setLists(listsResponse.info.lists);
      setPageState(PageState.SuccessState);
    };
    helper();
  }, [tabIndex]);

  if (
    pageState === PageState.InitialState ||
    pageState === PageState.LoadingState
  ) {
    return <Loading />;
  }

  const onMoveHandler = (index: number) => {
    if (index >= lists.length - 1) {
      return (restaurantId: string) => {
        return () => {
          console.log("last list alr", restaurantId);
        };
      };
    }

    return (restaurantId: string) => {
      const fromListId = lists[index].id;
      const toListId = lists[index + 1].id;

      return async () => {
        const moveRequest: MoveToListRequest = {
          restaurant_id: restaurantId,
          from_list_id: fromListId,
          to_list_id: toListId,
        };

        const res = await moveToList(moveRequest);

        if (!res || Object.keys(res).length === 0) {
          toast({
            status: "error",
            duration: 5000,
            isClosable: true,
            render: () => (
              <Box color="white" p={3} bg="teal.500" borderRadius={"5px"}>
                <Text as={"b"}>Error Occurred</Text>
                <Text>Please try again later</Text>
              </Box>
            ),
          });
          return;
        }

        const newRestList = [...lists[index].restaurants].filter(
          (val) => restaurantId !== val.id
        );
        const newList = [...lists];
        newList[index].restaurants = newRestList;

        setLists(newList);
        return;
      };
    };
  };

  const onRemoveHandler: (
    index: number
  ) => (restaurantId: string) => () => void = (index: number) => {
    return (restaurantId: string) => {
      return async () => {
        const removeItemRequest: RemoveFromListRequest = {
          restaurant_id: restaurantId,
          list_id: lists[index].id,
        };

        const res = await removeFromList(removeItemRequest);

        if (!res || Object.keys(res).length === 0) {
          toast({
            status: "error",
            duration: 5000,
            isClosable: true,
            render: () => (
              <Box color="white" p={3} bg="teal.500" borderRadius={"5px"}>
                <Text as={"b"}>Error Occurred</Text>
                <Text>Please try again later</Text>
              </Box>
            ),
          });
          return;
        }

        const newRestList = [...lists[index].restaurants].filter(
          (val) => restaurantId !== val.id
        );
        const newList = [...lists];
        newList[index].restaurants = newRestList;

        setLists(newList);
        return;
      };
    };
  };

  return (
    <Box p={4}>
      <Stack align="center">
        <Box w="95%" maxW="lg">
          <Stack>
            <Heading>List</Heading>
            {pageState === PageState.ErrorState ? (
              <ErrorOccurred text="Error Occurred. We are working on a fix and will be back online soon." />
            ) : (
              <Tabs
                onChange={(index) => {
                  setPageState(PageState.LoadingState);
                  setTabIndex(index);
                }}
                defaultIndex={tabIndex}
              >
                <TabList>
                  {lists.map((val) => (
                    <Tab key={val.id}>{val.name}</Tab>
                  ))}
                </TabList>
                <TabPanels>
                  {lists.map((val, i) => (
                    <TabPanel key={val.id}>
                      <RestaurantList
                        onRemoveHandler={onRemoveHandler(i)}
                        list={val.restaurants}
                        onMoveHandler={onMoveHandler(i)}
                      />
                    </TabPanel>
                  ))}
                </TabPanels>
              </Tabs>
            )}
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};

export default ListPage;
