import { Box, Center, Divider, HStack, Stack, Text } from "@chakra-ui/react";

import PostCard from "components/PostCard/PostCard";
import { MiniPost } from "types/post";

const RankList = ({ rankedMiniPost }: { rankedMiniPost: MiniPost[] }) => (
  <Stack>
    {rankedMiniPost.map((val, i) => {
      return (
        <HStack key={val.name + i}>
          <Box w={{ base: "10%", md: "20%" }}>
            <Center>
              <Text as={"b"} fontSize={"3xl"}>
                {i + 1}
              </Text>
            </Center>
          </Box>
          <Divider orientation={"vertical"} h={70} mr={{ md: 2 }} />
          <PostCard
            id={val.id}
            name={val.name}
            description={val.excerpt}
            created_at={val.created_at}
            score={val.score}
          />
        </HStack>
      );
    })}
  </Stack>
);

export default RankList;
