import { Box, Stack, Text } from "@chakra-ui/react";

import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";

const Loading = ({ loadText }: { loadText?: string }) => {
  return (
    <Box mt="5rem">
      <Stack align={"center"}>
        <LoadingSpinner />
        <Text>{!loadText ? "Loading..." : loadText}</Text>
      </Stack>
    </Box>
  );
};

export default Loading;
