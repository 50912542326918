import {
  FormControl,
  FormHelperText,
  FormLabel,
  Switch,
} from "@chakra-ui/react";
import { Control, Controller } from "react-hook-form";

const IsHiddenSwitch = ({ control }: { control: Control<any> }) => {
  return (
    <Controller
      control={control}
      name={"is_hidden"}
      render={({ field: { onChange, value, name } }) => {
        return (
          <FormControl>
            <FormLabel>Hidden?</FormLabel>
            <Switch
              name={name}
              colorScheme="teal"
              size={"lg"}
              onChange={onChange}
              isChecked={value}
            />
            <FormHelperText>Want to hide the post?</FormHelperText>
          </FormControl>
        );
      }}
    />
  );
};

export default IsHiddenSwitch;
