import { MiniPost } from "types/post";
import { Restaurant } from "types/restaurant";

const HomePostApi = `${process.env.REACT_APP_BE_API}/api/post/home`;

interface HomePostResponse {
  recommended: MiniPost;
  recent: MiniPost[];
  restaurants: Restaurant[];
}

const getHomePosts: () => Promise<HomePostResponse> =
  async (): Promise<HomePostResponse> => {
    let resPosts: HomePostResponse = {} as HomePostResponse;
    try {
      const response = await fetch(HomePostApi, {
        method: "POST",
        credentials: "include",
      });
      const resJson = await response.json();
      resPosts = resJson.data;
    } catch (err) {
      console.log(err);
      return {} as HomePostResponse;
    }

    return resPosts;
  };

export default getHomePosts;
