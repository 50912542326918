const useAvatarInfo = () => {
  const username = localStorage.getItem("username");
  const displayImage = localStorage.getItem("display_image");

  const avatarUsername = username ? username : "J P";
  const avatarDisplayImage = displayImage ? displayImage : "";

  return { avatarDisplayImage, avatarUsername };
};

export default useAvatarInfo;
