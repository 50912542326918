import { Box, Text } from "@chakra-ui/react";

const WrittenOn = ({
  updatedDate,
  createdDate,
}: {
  updatedDate: string;
  createdDate: string;
}) => {
  return (
    <Box>
      {updatedDate === createdDate ? (
        <Text>Written on: {createdDate}</Text>
      ) : (
        <Text>
          Written on: {createdDate} | Updated on: {updatedDate}
        </Text>
      )}
    </Box>
  );
};

export default WrittenOn;
