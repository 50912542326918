import { Stack, Text } from "@chakra-ui/react";

const PostDescription = ({ description }: { description: string[] }) => {
  return (
    <Stack>
      {description.map((val, i) => {
        return (
          <Text key={`${val.slice(0, 10)}${i}`} align={"justify"}>
            {val}
          </Text>
        );
      })}
    </Stack>
  );
};

export default PostDescription;
