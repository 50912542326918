import { Box, Heading, Stack, Text } from "@chakra-ui/react";

import Newsletter from "components/Newsletter/Newsletter";

const RecommendPage = () => (
  <Box p={4} minH={"75vh"}>
    <Stack align={"center"}>
      <Box w={"95%"} maxW={"lg"}>
        <Heading>Recommendation (coming soon)</Heading>
        <Box mt={5}>
          <Text>
            Our team is working hard to help you discover food in Singapore.
            Sign up below to be notified when it is released!
          </Text>
        </Box>
        <Box mt={5}>
          <Newsletter />
        </Box>
        <Box mt={5}>
          <Text>
            *The email will only be used for new feature notification purpose
            only.
          </Text>
        </Box>
      </Box>
    </Stack>
  </Box>
);

export default RecommendPage;
