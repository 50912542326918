import {
  Box,
  Card,
  Center,
  Divider,
  HStack,
  Icon,
  LinkBox,
  LinkOverlay,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  Text,
} from "@chakra-ui/react";
import { IoIosPodium } from "react-icons/io";
import { ReactElement } from "react";
import { FaMapMarkerAlt } from "react-icons/fa";

const getRankColor = (position: number, total: number): string => {
  if (position > 5) {
    return "red";
  }
  return "green";
};

const CATEGORY_RankCard_Type = "category";
const LOCATION_RankCard_Type = "location";

const isValidRankCardType = (type: string): boolean => {
  if (type === CATEGORY_RankCard_Type) {
    return true;
  } else if (type === LOCATION_RankCard_Type) {
    return true;
  }
  return false;
};

const getIcon = (type: string): ReactElement => {
  switch (type) {
    case CATEGORY_RankCard_Type:
      return <Icon as={IoIosPodium} boxSize={"75%"} />;
    case LOCATION_RankCard_Type:
      return <Icon as={FaMapMarkerAlt} boxSize={"65%"} />;
  }
  return <Icon as={IoIosPodium} boxSize={"100%"} />;
};

const getStatHelpText = (type: string, total: number): ReactElement => {
  switch (type) {
    case CATEGORY_RankCard_Type:
      return (
        <StatHelpText>
          Against <Text as={"b"}>{total}</Text> other similar restaurants
        </StatHelpText>
      );
    case LOCATION_RankCard_Type:
      return (
        <StatHelpText>
          Against <Text as={"b"}>{total}</Text> other nearby restaurants
        </StatHelpText>
      );
  }
  return <></>;
};

const RankCard = ({
  position,
  total,
  type,
  tags,
}: {
  position: number;
  total: number;
  type: string;
  tags: string[];
}) => {
  const isValid = isValidRankCardType(type);

  if (!isValid) {
    return <></>;
  }

  const hrefLink =
    type === LOCATION_RankCard_Type
      ? `/ranking?locations=${tags.join(",")}`
      : `/ranking?tags=${tags.join(",")}&sm=50`;
  return (
    <>
      <LinkBox>
        <Card w={"290px"} borderColor={"black"}>
          <Box p={2} shadow={"md"}>
            <LinkOverlay href={hrefLink}>
              <HStack spacing={2}>
                <Box h={"100%"} w={"30%"}>
                  <Center>{getIcon(type)}</Center>
                </Box>
                <Divider
                  orientation="vertical"
                  h={"75px"}
                  borderWidth={"1px"}
                  borderRadius={"1px"}
                />
                <Stat ml={4}>
                  <StatLabel>Ranking</StatLabel>
                  <StatNumber color={getRankColor(position, total)}>
                    No. {position}
                  </StatNumber>
                  {getStatHelpText(type, total)}
                </Stat>
              </HStack>
            </LinkOverlay>
          </Box>
        </Card>
      </LinkBox>
    </>
  );
};

export default RankCard;
