import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Center,
  HStack,
  Heading,
  Icon,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";

import { MiniPost } from "types/post";
import PostCard from "components/PostCard/PostCard";
import { TimeIcon } from "@chakra-ui/icons";
import OverallFScore from "components/PostPage/F_score/OverallFScore";
import { PageState } from "types/page_state";
import { Restaurant } from "types/restaurant";
import { RestaurantGroupInfo } from "types/restaurantGroup";
import getRestaurantGroup from "services/restaurantGroup/getRestaurantGroup";
import Loading from "components/Loading/Loading";
import ErrorOccurred from "components/Errors/NotFound";
import { generateRestaurantWithIdRoute } from "helpers/generateRoutes";

/*
Types of groups
Different Branches - 1
Different Hawker Centers - 2
*/
const RestaurantGroupPage = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const [state, setState] = useState<PageState>(PageState.InitialState);

  const [restaurantInfo, setRestaurantInfo] = useState<
    Restaurant[] | undefined
  >();
  const [averageScore, setAverageScore] = useState(0);
  const [groupInfo, setGroupInfo] = useState<RestaurantGroupInfo | undefined>();
  const [recentPosts, setRecentPosts] = useState<MiniPost[] | undefined>();

  useEffect(() => {
    const helper = async () => {
      setState(PageState.LoadingState);
      const resGroupInfo = await getRestaurantGroup(id);

      if (!resGroupInfo) {
        setState(PageState.ErrorState);
        return;
      }

      if (Object.keys(resGroupInfo).length === 0) {
        setState(PageState.ErrorState);
        return;
      }

      setGroupInfo(resGroupInfo.info);
      setRecentPosts(resGroupInfo.recent_posts);
      setRestaurantInfo(resGroupInfo.restaurants);
      setAverageScore(resGroupInfo.average_score);

      setState(PageState.SuccessState);
    };

    helper();
  }, [id]);

  const headerImage = groupInfo?.header_image ? groupInfo?.header_image : "";

  const groupName = groupInfo?.name ? groupInfo.name : "Restaurant No Name";

  // different types will need different layouts
  // const type = 1;

  const description = groupInfo?.description ? groupInfo.description : "";

  //   const position: LatLngExpression = [1, 103];
  //   const googleMapsLink = "https://google.com";

  const onClickRestaurant = (restaurantId: string) => {
    return () => navigate(generateRestaurantWithIdRoute(restaurantId));
  };

  if (state === PageState.LoadingState || state === PageState.InitialState) {
    return <Loading />;
  }

  if (state === PageState.ErrorState) {
    return <ErrorOccurred text="Restaurant not found" />;
  }

  return (
    <Box p={4}>
      <Stack align={"center"}>
        <Box w={"95%"} maxW={"lg"}>
          <Stack>
            <Box w={"100%"} mb={"-30"}>
              <Image
                src={headerImage}
                h={"25vh"}
                w={"100%"}
                fit={"cover"}
                borderRadius={3}
              />
              <Box
                position={"relative"}
                transform={"auto"}
                translateY={"-30%"}
                w={"90%"}
                ml={"auto"}
                mr={"auto"}
              >
                <Card>
                  <CardBody>
                    <Heading size="lg" my="2">
                      {groupName}
                    </Heading>
                    <Text>Group ID: {id}</Text>
                    <Text>{description}</Text>
                    <Text>Average Score: {averageScore}/10</Text>
                  </CardBody>
                </Card>
              </Box>
            </Box>
            <Heading mb={4}>Branches</Heading>
            <Box h={"400px"}>
              <HStack p={1} spacing={4} overflowX="auto" h={"100%"}>
                {restaurantInfo &&
                  restaurantInfo.map((val) => {
                    return (
                      <Box key={val.id} h={"100%"}>
                        <Card w={{ base: "230px", md: "225px" }} h={"100%"}>
                          <Image
                            src={val.header_image}
                            alt={val.name}
                            borderTopRadius={"lg"}
                            h={"40%"}
                            w={"100%"}
                            objectFit={"cover"}
                          />
                          <CardHeader pt={"10px"} pb={"0px"}>
                            <Heading size="md" noOfLines={3}>
                              {val.name}
                            </Heading>
                          </CardHeader>
                          <CardBody py={"5px"} overflowY={"auto"}>
                            <Stack>
                              <Box>
                                <Text>Address:</Text>
                                {val.location_details.address
                                  .split("\n")
                                  .map((addLine) => (
                                    <Text key={addLine}>{addLine}</Text>
                                  ))}
                              </Box>
                              <OverallFScore
                                postScore={val.average_score}
                                hideInfoButton={true}
                              />
                            </Stack>
                          </CardBody>
                          <CardFooter py={"10px"}>
                            <Button
                              onClick={onClickRestaurant(val.id)}
                              w={"100%"}
                            >
                              View more
                            </Button>
                          </CardFooter>
                        </Card>
                      </Box>
                    );
                  })}
              </HStack>
            </Box>

            <Box w={"100%"} alignContent={"left"} pl={4}>
              <Heading mb={4}>Posts</Heading>
              <Stack>
                {recentPosts && recentPosts.length ? (
                  recentPosts.map((val) => {
                    return (
                      <PostCard
                        key={val.id}
                        id={val.id}
                        name={val.name}
                        description={val.excerpt}
                        created_at={val.created_at}
                        score={val.score}
                      />
                    );
                  })
                ) : (
                  <Center h={"xs"}>
                    <Stack align={"center"}>
                      <Icon as={TimeIcon} boxSize={"7"} />
                      <Text>No Recent Posts...</Text>
                    </Stack>
                  </Center>
                )}
              </Stack>
            </Box>
            {/* <Box w={"100%"} alignContent={"left"} pl={4}>
              <Heading mb={4}>Location</Heading>
              <Box h={"40vh"}>
                <PostMap
                  markerPosition={position}
                  addressText={addressInfo}
                  googleMapsLink={googleMapsLink}
                  restaurantName={restaurantName}
                />
              </Box>
            </Box> */}
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};

export default RestaurantGroupPage;
