import axios from "axios";
import { AuthInfo } from "types/auth";

const registerService = async (
  username: string,
  password: string,
  email: string,
  referralKey: string
): Promise<AuthInfo> => {
  const res = await axios.post(
    `${process.env.REACT_APP_BE_API}/api/auth/register`,
    {
      username: username,
      password: password,
      email: email,
      referral_key: referralKey,
    }
  );

  return res.data?.data;
};

export default registerService;
