import { FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/react";
import { AsyncSelect } from "chakra-react-select";
import { Control, Controller } from "react-hook-form";

import getTags from "services/tags/GetTags";

const TagsPicker = ({ control }: { control: Control<any> }) => {
  const promiseOptions = (inputValue: string) =>
    new Promise<{ value: string; label: string }[]>(async (resolve) => {
      const getTagsResponse = await getTags(inputValue);
      const tags = getTagsResponse.tags;
      if (!tags) {
        resolve([]);
        return;
      }
      const tagsMapped = tags.map((val) => {
        return { value: val, label: val };
      });
      const tagsFiltered = tagsMapped.filter((i) =>
        i.label.toLowerCase().includes(inputValue.toLowerCase())
      );
      resolve(tagsFiltered);
    });

  return (
    <Controller
      control={control}
      name={"tags"}
      rules={{ required: "Please enter at least one tag." }}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, error },
      }) => {
        return (
          <FormControl isInvalid={invalid}>
            <FormLabel>Tags</FormLabel>
            <AsyncSelect
              isMulti
              cacheOptions
              defaultOptions
              closeMenuOnSelect={false}
              name={name}
              ref={ref}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              loadOptions={promiseOptions as any}
              isClearable={false}
            />
            <FormErrorMessage>{error && error.message}</FormErrorMessage>
          </FormControl>
        );
      }}
    />
  );
};

export default TagsPicker;
