import { FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/react";
import { AsyncSelect } from "chakra-react-select";
import { useEffect, useState } from "react";
import { Control, Controller, UseFormSetValue } from "react-hook-form";

import searchRestaurants from "services/restaurant/SearchRestaurants";
import getRestaurant from "services/restaurant/getRestaurant";

const RestaurantPicker = ({
  control,
  preselected,
  setVal,
}: {
  control: Control<any>;
  preselected?: string;
  setVal?: UseFormSetValue<any>;
}) => {
  const [restVal, setRestVal] = useState<{ label: string; value: string }>();

  useEffect(() => {
    const helper = async () => {
      if (!preselected) {
        return;
      }

      const rest = await getRestaurant(preselected);
      if (!rest) {
        return;
      }

      if (Object.keys(rest).length === 0) {
        return;
      }

      setRestVal({ value: rest.info.id, label: rest.info.name });
      if (!!setVal) {
        console.log("setted");
        setVal("restaurant_id", { value: rest.info.id, label: rest.info.name });
      }
    };

    helper();
  }, [preselected, setVal]);
  // restaurant_id=651e3f23a2fa7a290f9140a7

  const promiseOptions = (inputValue: string) =>
    new Promise<{ value: string; label: string }[]>(async (resolve) => {
      const searchRestaurantsResponse = await searchRestaurants(inputValue);
      const restaurants = searchRestaurantsResponse.restaurants;
      if (!restaurants) {
        resolve([]);
        return;
      }
      const restaurantsMapped = restaurants.map((val) => {
        return { value: val.id, label: val.name };
      });
      const tagsFiltered = restaurantsMapped.filter((i) =>
        i.label.toLowerCase().includes(inputValue.toLowerCase())
      );
      resolve(tagsFiltered);
    });

  return (
    <Controller
      control={control}
      name={"restaurant_id"}
      rules={{ required: "Pick a restaurant to review." }}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, error },
      }) => {
        const changeWrapper = () => {
          setRestVal(value);
          return onChange;
        };
        return (
          <FormControl isInvalid={invalid}>
            <FormLabel>Restaurant</FormLabel>
            <AsyncSelect
              cacheOptions
              defaultOptions
              closeMenuOnSelect={true}
              name={name}
              ref={ref}
              onChange={changeWrapper}
              onBlur={onBlur}
              value={restVal}
              loadOptions={promiseOptions as any}
              isClearable={false}
            />
            <FormErrorMessage>{error && error.message}</FormErrorMessage>
          </FormControl>
        );
      }}
    />
  );
};

export default RestaurantPicker;
