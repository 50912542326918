import { useState } from "react";

import uploadImageService from "../services/uploadImage/UploadImage";

const useImageUpload = () => {
  const [images, setImages] = useState<string[]>([]);
  const [isUploading, setIsUploading] = useState(false);

  const uploadImages = async (imageFile: FileList | null) => {
    if (!imageFile) {
      console.log("no file found!");
      return;
    }
    setIsUploading(true);

    console.log(Array.from(imageFile));

    const newImages = await Promise.all(
      Array.from(imageFile).map(
        async (image) => await uploadImageService(image)
      )
    );

    setImages((_images) => images.concat(newImages));
    setIsUploading(false);
  };

  const removeImage = (imageToRemove: string) => {
    setImages((_images) => _images.filter((i) => i !== imageToRemove));
  };

  return { uploadImages, isUploading, removeImage, images };
};

export default useImageUpload;
